@import "../../../App.module.scss";

@keyframes navBlock1Animation {
  0% {
    left: 102%;
  }
  100% {
    left: -50%;
  }
}
@keyframes navBlock2Animation {
  0% {
    left: 155%;
  }
  100% {
    left: -50%;
  }
}
@keyframes offOpacityAnimation {
  0% {
  }
  100% {
    opacity: 0;
  }
}
.navBlock1 {
  position: relative;
  display: flex;
  min-width: 1000px;

  // animation: navBlock1Animation 15s 0s infinite ease-in;
}
.navBlock2 {
  position: relative;
  display: flex;
  min-width: 1000px;
  // left: 92px;
  // animation: navBlock2Animation 18s 0s infinite ease-in;
}
.navBlock3 {
  min-width: 1000px;
  position: relative;
  display: flex;
  // left: 192px;
  // animation: navBlock2Animation 18s 0s infinite ease-in;
}

.servicesBlock {
  padding: 60px 0px 0px 0px;
  background-color: #f4eee2;
  position: relative;
  // display: flex;
  // flex-direction: column;
  // justify-content: space-between;
}
.servicesBlockHide {
  padding: 60px 20px 0px 0px;
  background-color: #ece3ce;
  position: relative;
  height: 100vh;
  animation: offOpacityAnimation 1s 0s forwards;
}

.servicesBlockSubtitleTitle {
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: $grey;
  margin-bottom: 8px;
  margin-left: 20px;
}

.servicesBlockSubtitleText {
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 800;
  font-size: 40px;
  line-height: 48px;
  text-transform: uppercase;
  color: $black;
  margin-bottom: 25px;
  letter-spacing: -0.02em;
  margin-left: 20px;
}

@media (max-height: 600px) {
  .servicesBlockSubtitleTitle {
    font-family: "Urbanist";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: $grey;
    margin-bottom: 8px;
    margin-left: 20px;
  }

  .servicesBlockSubtitleText {
    font-family: "Urbanist";
    font-style: normal;
    font-weight: 800;
    font-size: 34px;
    line-height: 42px;
    text-transform: uppercase;
    color: $black;
    margin-bottom: 20px;
    letter-spacing: -0.02em;
    margin-left: 20px;
  }
}
.servicesBlockServicesList {
  width: 101vw;
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 31px;
  background-color: #d2d628;
  flex-shrink: 0;
  // position: relative;
  padding: 3px 0px;
}

.servicesBlockMobailMoveServicesList {
  width: 101vw;
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 35px;
  background-color: #b1add4;
  flex-shrink: 0;
  position: relative;
  left: 50%;
  transform: translate(-50%);
  justify-content: space-between;
  z-index: 5;
  position: fixed;
  top: 0px;
  padding: 3px 0px;
}
.servicesBlockMobailServicesList {
  width: 101vw;
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 35px;
  background-color: #b1add4;
  position: relative;
  justify-content: space-between;
  padding: 3px 0px;
}
.servicesBlockServicesListMobile {
  width: 220vw;
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 35px;
  background-color: #b1add4;
  flex-shrink: 0;
  position: relative;
  left: 50%;
  transform: translate(-50%);
  transition: 2s;
}

.servicesBlockServicesListMobile {
  width: 220vw;
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 35px;
  background-color: #b1add4;
  flex-shrink: 0;
  position: relative;
  left: 50%;
  transform: translate(-50%);
  transition: 2s;
}

.servicesBlockServicesListItem {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  background-color: #d2d628;
}

.servicesBlockMobileServicesListItem {
  width: 190px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  text-decoration: none;
  color: $black;
  padding: 8px 10px;
  border: 1px solid rgba(0, 0, 0, 0);
  cursor: pointer;
  position: relative;
}

.servicesBlockMobileActiveServicesListItem {
  width: 190px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  text-decoration: none;
  color: $black;
  border-radius: 1000px;
  padding: 8px 10px;
  background-color: $black;
  transition: 1s;
  color: white;
  margin-left: auto;
  margin-right: auto;
}

.servicesBlockServicesListItemText {
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  text-decoration: none;
  color: $black;
  padding: 8px 32px;
  border: 1px solid rgba(0, 0, 0, 0);
  cursor: pointer;
}

.activeLink {
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  text-decoration: none;
  color: $black;
  border-radius: 1000px;
  padding: 8px 32px;
  background-color: $black;
  // transition: 1s;
  color: white;
}
.servicesMobileBlockPages {
  margin-left: 20px;
  animation: servicesContentAnimation 1s 0s forwards;
}
.servicesMobailBlockPagesFooter {
  // display: flex;
  // justify-content: space-between;
  // align-items: center;
  margin-top: 20px;
}
.servicesMobailBlockPagesCircles {
  display: flex;
  justify-content: center;
}
.servicesMobailBlockPagesCirclesItem {
  width: 8px;
  height: 8px;
  background-color: #cbc9e2;
  border-radius: 100px;
  margin-right: 8px;
  &:last-child {
    margin-right: 0px;
  }
  cursor: pointer;
}
.servicesMobailBlockPagesCirclesItemActive {
  width: 68px;
  height: 8px;
  background-color: #656093;
  border-radius: 100px;
  margin-right: 8px;
  &:last-child {
    margin-right: 0px;
  }
}
// .servicesDesktopBlockPages {
//   padding-left: 20px;
//   padding-right: 20px;
// }
@media (min-width: 982px) {
  // .servicesMobailBlockPagesFooter {
  //   display: none;
  // }
}

@media (min-width: 466px) {
  .servicesBlockMobileServicesListItem {
    width: 234px;
    display: flex;
    justify-content: center;
    flex-shrink: 0;
    font-family: "Urbanist";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    text-decoration: none;
    color: $black;
    padding: 8px 32px;
    border: 1px solid rgba(0, 0, 0, 0);
    cursor: pointer;
  }

  .servicesBlockMobileActiveServicesListItem {
    width: 234px;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-shrink: 0;
    font-family: "Urbanist";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    text-decoration: none;
    color: $black;

    border-radius: 1000px;
    padding: 8px 32px;
    background-color: $black;
    transition: 1s;
    color: white;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 600px) {
  .servicesBlockMobailServicesList {
    width: 101vw;
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 35px;
    background-color: #b1add4;
    flex-shrink: 0;
    position: relative;
    justify-content: space-between;
    padding-left: 0px;
    animation: moveAnimation 0s 1s 1;
  }
  .servicesBlockMobailMoveServicesList {
    width: 101vw;
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 35px;
    background-color: #b1add4;
    flex-shrink: 0;
    position: relative;
    justify-content: space-between;
    padding-left: 0px;
    z-index: 5;
    position: fixed;
    top: 0px;
    animation: moveAnimation 0s 1s 1;
  }
}
@media (min-width: 982px) {
  .servicesDesktopBlockPagesWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    // padding-left: 53px;
    // padding-right: 53px;
  }
  .servicesDesktopBlockPages {
    max-width: 1066px;
    padding-left: 20px;
    padding-right: 20px;
    // margin-left: auto;
    // margin-right: auto;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // margin-left: auto;
  }
  .servicesBlockSubtitle {
    display: flex;
    align-items: center;
    max-width: 1066px;
    margin-left: auto;
    margin-right: auto;
  }
  .servicesBlockSubtitleTitle {
    margin-left: 10px;
  }
  .servicesBlockSubtitleText {
    margin-left: 10px;
  }

  .fullCycleWrapper {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media (min-width: 1050px) {
  .servicesBlockSubtitle {
    display: flex;
    align-items: center;
    padding-left: 10px;
    max-width: 1066px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 1060px) {
  .servicesBlockSubtitle {
    display: flex;
    align-items: center;
    padding-left: 25px;
    max-width: 1066px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 1366px) {
  .servicesBlock {
    padding: 129px 0px 0px 0px;
  }
  .servicesBlockSubtitle {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 40px;
  }
  .servicesBlockSubtitleWrapper {
    min-width: 1066px;
  }
  .servicesBlockSubtitleTitle {
    font-family: "Urbanist";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: $grey;
    margin-bottom: 8px;
    margin-left: auto;
  }
  .servicesBlockSubtitleText {
    font-family: "Urbanist";
    font-style: normal;
    font-weight: 800;
    font-size: 60px;
    line-height: 60px;
    text-transform: uppercase;
    color: $black;
    margin-bottom: 40px;
    margin-left: auto;
  }
  .servicesBlockServicesList {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 35px;
    background-color: #d2d628;
    // flex-shrink: 0;
    // position: relative;
    gap: 70px;
  }

  .servicesBlockServicesListItem {
    display: flex;
    // flex-shrink: 0;
    align-items: center;
  }

  .servicesBlockServicesListItemText {
    font-family: "Urbanist";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    text-decoration: none;
    color: $black;
    padding: 8px 32px;
    margin-right: 16px;
    border: 1px solid rgba(0, 0, 0, 0);
    cursor: pointer;
  }

  .activeLink {
    font-family: "Urbanist";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    text-decoration: none;
    color: $black;
    border-radius: 1000px;
    padding: 8px 32px;
    margin-right: 16px;
    background-color: $black;
    // transition: 1s;
    color: white;
  }
  .servicesDesktopBlockPagesWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    // padding-left: 53px;
    // padding-right: 53px;
  }
  .servicesDesktopBlockPages {
    max-width: 1066px;
    padding-left: 0px;
    padding-right: 0px;
    margin-left: 20px;
    margin-right: 20px;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // margin-left: auto;
  }
}

.buttonsCase {
  position: absolute;
  top: 0;
}

.servicesButton {
  padding: 19px 34px 19px 34px;
  background: rgba(244, 238, 226, 1);
  border-radius: 100px;
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: $black;
  cursor: pointer;
  border: 1.5px solid rgba(125, 119, 183, 1);
  color: #060403;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 90px;
  &:hover {
    background-color: rgba(6, 4, 3, 1);
  }
  &:hover svg path {
    fill: rgba(125, 119, 183, 1);
  }
}
.servicesButton svg path {
  fill: rgba(125, 119, 183, 1);
}
.servicesBackButton {
  padding: 19px 34px 19px 34px;
  background: rgba(244, 238, 226, 1);
  border-radius: 100px;
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: $black;
  cursor: pointer;
  border: 1.5px solid rgba(125, 119, 183, 1);
  color: #060403;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 90px;
  &:hover {
    background-color: rgba(6, 4, 3, 1);
  }
  &:hover svg path {
    fill: rgba(125, 119, 183, 1);
  }
}
.servicesBackButton svg path {
  fill: rgba(125, 119, 183, 1);
}
.servicesMobailButton {
  padding: 14px 32px 14px 32px;
  background: #dbde53;
  border-radius: 100px;
  font-family: "Urbanist";
  font-style: normal;
  letter-spacing: 0.02em;
  color: $white;
  cursor: pointer;
  border: none;
  color: #060403;
  display: flex;
  justify-content: center;
  align-items: center;
}

.servicesMobileBackButton {
  padding: 14px 32px 14px 32px;
  background: #dbde53;
  border-radius: 100px;
  font-family: "Urbanist";
  font-style: normal;
  letter-spacing: 0.02em;
  color: $black;
  cursor: pointer;
  margin-right: 10px;
  border: none;
  color: #060403;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 982px) {
  .buttonsCase {
    display: none;
  }
  .servicesButton {
    display: none;
  }

  .servicesBackButton {
    display: none;
  }
}
@media (max-width: 1277px) {
  .servicesButton {
    display: none;
  }
  .servicesBackButton {
    display: none;
  }
}
