@import "../../App.module.scss";

@keyframes zoom-circle {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.03, 1.03);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes zoom-circle-reverse {
  0% {
    transform: scale(1.03, 1.03);
  }

  100% {
    transform: scale(1, 1);
  }
}

.footer {
  background-color: $violet;
  text-align: center;
  position: relative;
}

.footerWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.footerCircle {
  width: 488px;
  height: 488px;
  border-radius: 50%;
  background-color: #ece3ce;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 85px 72px 35px 72px;
  margin-bottom: 50px;
  position: relative;
  transition: 1s;
}
.footerCircleContentWrapper {
  position: relative;
  z-index: 1;
}
.footerCircleClone {
  position: absolute;
  width: 488px;
  height: 488px;
  border-radius: 50%;
  background-color: #ece3ce;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 85px 72px 35px 72px;
  margin-bottom: 24px;
  // animation: zoom-circle 2s 0s infinite;
  top: 0;
  transition: 1s;
}

.footerCircleTitle {
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  margin-bottom: 5px;
}

.footerCircleText {
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 800;
  font-size: 40px;
  line-height: 48px;
  letter-spacing: -0.02em;
  color: $black;
  margin-bottom: 32px;
}

.footerCircleButton {
  width: 100%;
  padding: 14px 42px;
  background-color: $black;
  color: $white;
  border-radius: 100px;
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.02em;
  margin-bottom: 60px;
  cursor: pointer;
  transition: 1s;
  border: 2px solid rgba(6, 4, 3, 1);
}

.footerCircleCaseWrapper {
  padding: 11px 12px 10px;
  background-color: $yellow;
  margin-bottom: 40px;
}

.footerCircleCase {
  padding: 21px 27px;
  border: 2.65176px solid #060403;
}

.footerCircleLinks {
  margin-bottom: 16px;
}
.footerCircleLinks a {
  margin-right: 16px;
  &:last-child {
    margin-right: 0px;
  }
  &:hover svg path {
    fill: rgba(210, 214, 40, 1);
  }
}

.footerCircleCaseText {
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 18px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  text-decoration: none;
  color: $black;
}

.footerCircleCoordinatesText {
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: $grey;
  letter-spacing: 0.02em;
}

.footerText {
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #36324b;
  position: relative;
  top: -20px;
}
@media (max-width: 600px) {
  .footerText {
    font-family: "Urbanist";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #36324b;
    position: relative;
    top: -20px;
  }
}
@media (min-width: 800px) {
  .footer {
    background-color: #7d77b7;
    text-align: center;
    position: relative;
    height: 100vh;
  }

  .footerCircleButton {
    width: auto;
    padding: 14px 42px;
    background-color: $black;
    color: $white;
    border-radius: 100px;
    border: 2px solid rgba(6, 4, 3, 1);
    font-family: "Urbanist";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.02em;
    margin-bottom: 18px;
    cursor: pointer;
  }
  .footerCircleCaseWrapper {
    padding: 11px 12px 10px;
    background-color: $yellow;
    margin-bottom: 60px;
  }
}
@media (min-width: 1366px) {
  .footerCircleClone {
    position: absolute;
    width: 570px;
    height: 570px;
    border-radius: 50%;
    background-color: #ece3ce;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 85px 72px 35px 72px;
    margin-bottom: 24px;
    animation: zoom-circle 2s 0s infinite;
    top: 0;
    left: 0;
    transition: 1s;
  }
  .footerCircle {
    width: 570px;
    height: 570px;
    border-radius: 50%;
    background-color: #ece3ce;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 178px 92px 35px;
    margin-bottom: 50px;
    position: relative;
    animation: zoom-circle 2s 0s infinite;
    &:hover {
      width: 570px;
      height: 570px;
      border-radius: 50%;
      background-color: #ece3ce;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 178px 92px 35px;
      margin-bottom: 50px;
      position: relative;
      transition: 1s;
      animation: none;
      animation: zoom-circle-reverse 2s 0s forwards;
    }
    &:hover .footerCircleClone {
      position: absolute;
      width: 570px;
      height: 570px;
      border-radius: 50%;
      background-color: #ece3ce;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 85px 72px 35px 72px;
      margin-bottom: 50px;
      animation: none;
      transition: 1s;
      animation: zoom-circle-reverse 2s 0s forwards;
    }

    // &:hover .footerCircleButton {
    //   padding: 14px 42px;
    //   background-color: $black;
    //   color: $white;
    //   border-radius: 100px;
    //   border: none;
    //   font-family: "Urbanist";
    //   font-style: normal;
    //   font-weight: 700;
    //   font-size: 16px;
    //   line-height: 22px;
    //   letter-spacing: 0.02em;
    //   margin-bottom: 24px;
    //   cursor: pointer;
    //   transition: 1s;
    //   transform: scale(1.5);
    //   background-color: rgba(255, 255, 255, 1);
    //   border: 2px solid rgba(6, 4, 3, 1);
    //   color: rgba(6, 4, 3, 1);
    // }
  }
  .footerCircleButton {
    &:hover {
      padding: 14px 42px;
      background-color: $black;
      color: $white;
      border-radius: 100px;
      border: none;
      font-family: "Urbanist";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.02em;
      margin-bottom: 24px;
      cursor: pointer;
      transition: 1s;
      transform: scale(1.5);
      background-color: rgba(255, 255, 255, 1);
      border: 2px solid rgba(6, 4, 3, 1);
      color: rgba(6, 4, 3, 1);
    }
  }
  .footerCircleTitle {
    font-family: "Urbanist";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    margin-bottom: 5px;
  }

  .footerCircleText {
    font-family: "Urbanist";
    font-style: normal;
    font-weight: 800;
    font-size: 60px;
    line-height: 60px;
    letter-spacing: -0.02em;
    color: $black;
    margin-bottom: 32px;
  }

  .footerCircleButton {
    padding: 14px 42px;
    background-color: $black;
    color: $white;
    border-radius: 100px;
    border: 2px solid rgba(6, 4, 3, 1);
    font-family: "Urbanist";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.02em;
    margin-bottom: 18px;
    cursor: pointer;
  }

  .footerCircleCaseWrapper {
    padding: 11px 8px 10px 11px;
    background-color: $yellow;
    margin-bottom: 50px;
    transform: rotate(-3.55deg);
    position: relative;
    left: 230px;
  }

  .footerCircleCase {
    padding: 30px 28px;
    border: 2.65176px solid #060403;
  }

  .footerCircleCaseText {
    font-family: "Urbanist";
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 18px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
  }

  .footerCircleLinks {
    margin-bottom: 16px;
  }

  .footerCircleCoordinatesText {
    font-family: "Urbanist";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: $grey;
    letter-spacing: 0.02em;
  }

  .footerText {
    font-family: "Urbanist";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #36324b;
  }
}

@media (min-width: 1600px) {
  .footerCircleClone {
    position: absolute;
    width: 680px;
    height: 680px;
    border-radius: 50%;
    background-color: #ece3ce;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 85px 72px 35px 72px;
    margin-bottom: 24px;
    animation: zoom-circle 2s 0s infinite;
  }
  .footerCircle {
    width: 680px;
    height: 680px;
    border-radius: 50%;
    background-color: #ece3ce;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 178px 92px 35px;
    margin-bottom: 70px;
    position: relative;
    &:hover {
      width: 680px;
      height: 680px;
      border-radius: 50%;
      background-color: #ece3ce;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 178px 92px 35px;
      margin-bottom: 70px;
      position: relative;
      transition: 1s;
      animation: zoom-circle-reverse 2s 0s forwards;
    }
    &:hover .footerCircleClone {
      position: absolute;
      width: 680px;
      height: 680px;
      border-radius: 50%;
      background-color: #ece3ce;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 85px 72px 35px 72px;
      margin-bottom: 24px;

      animation: zoom-circle-reverse 2s 0s forwards;
    }
  }
  .footerCircleCaseWrapper {
    padding: 11px 8px 10px 11px;
    background-color: $yellow;
    margin-bottom: 100px;
    transform: rotate(-3.55deg);
    position: relative;
    left: 230px;
  }
}

@media (min-width: 1920px) {
  .footerCircleClone {
    position: absolute;
    width: 820px;
    height: 820px;
    border-radius: 50%;
    background-color: #ece3ce;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 85px 72px 35px 72px;
    margin-bottom: 24px;
    animation: zoom-circle 2s 0s infinite;
  }
  .footerCircle {
    width: 820px;
    height: 820px;
    border-radius: 50%;
    background-color: #ece3ce;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 300px 92px 35px;
    margin-bottom: 35px;
    position: relative;
    &:hover {
      width: 820px;
      height: 820px;
      border-radius: 50%;
      background-color: #ece3ce;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 300px 92px 35px;
      margin-bottom: 35px;
      position: relative;
      transition: 1s;
      animation: zoom-circle-reverse 2s 0s forwards;
    }
    &:hover .footerCircleClone {
      position: absolute;
      width: 820px;
      height: 820px;
      border-radius: 50%;
      background-color: #ece3ce;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 85px 72px 35px 72px;
      margin-bottom: 24px;

      animation: zoom-circle-reverse 2s 0s forwards;
    }
  }
  .footerText {
    margin-top: 20px;
  }
}
