@import "../../../../../App.module.scss";

@keyframes textAnimation {
  0% {
    opacity: 0;
    left: 200px;
  }
  100% {
    opacity: 1;
    left: 0px;
  }
}
@keyframes textAnimationOff {
  0% {
  }
  100% {
    opacity: 0;
  }
}
@keyframes blocksAnimationMobail {
  0% {
    height: 0px;
  }
  100% {
    height: 120px;
  }
}

@keyframes blocksAnimation {
  0% {
    height: 0px;
  }
  100% {
    height: 136px;
  }
}
.designWrapper {
  animation: textAnimation 2s 0s forwards;
  padding-left: 20px;
  padding-right: 20px;
  min-height: 346px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.designWrapperHide {
  animation: textAnimationOff 2s 0s forwards;
}

.designDescription {
  position: relative;
}

.designDescriptionText {
  min-width: 340px;
  max-width: 754px;
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: $greyLight;
  letter-spacing: 0.02em;
  margin-bottom: 24px;
}

@media (max-height: 600px) {
  .designDescriptionText {
    min-width: 340px;
    max-width: 754px;
    font-family: "Urbanist";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: $greyLight;
    letter-spacing: 0.02em;
    margin-bottom: 13px;
  }
}

.designBlock {
  background-image: url(../../../../../assets/DesignIMG.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  animation: textAnimation;
  animation-duration: 1s;
  width: 100%;
  min-height: 134px;
}

@media (min-width: 660px) {
  .designBlock {
    background-image: url(../../../../../assets/DesignIMG.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    animation: textAnimation;
    animation-duration: 1s;
    width: 100%;
    min-height: 233px;
  }
}
@media (min-width: 1100px) {
  .designBlockWrapper {
    justify-content: start;
  }
}

@media (min-width: 1366px) {
  .designDescriptionText {
    min-width: 340px;
    max-width: 1066px;
    font-family: "Urbanist";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: $greyLight;
    letter-spacing: 0.02em;
    margin-bottom: 26px;
    min-height: auto;
  }
}
