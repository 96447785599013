@import "../../../App.module.scss";

.TitlePageDescriptioncWrapper {
  margin-left: auto;
  margin-right: auto;
  margin-top: 250px;
  display: flex;
  justify-content: center;
  align-items: end;
  flex-wrap: wrap;
}
.TitlePageTitle {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
  font-size: 65px;
  line-height: 65px;
  letter-spacing: -0.02em;
}

.TitlePageText {
  font-family: "Urbanist";
  max-width: 120px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  margin-top: 6px;
}

.TitlePageCase {
  width: 100px;
  background-color: $yellow;
  transform: rotate(-6.1deg);
  text-align: center;
  padding: 4px 5px;
  margin-top: 5px;
  transform: rotate(-7.37deg);
}

.TitlePageSubtitle {
  text-transform: uppercase;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 16px;
  letter-spacing: -0.02em;
  border: 2.92871px solid #060403;
  padding: 12px 15px 12px 15px;
}

@media (min-width: 320px) {
  .TitlePageDescriptioncWrapper {
    margin-left: auto;
    margin-right: auto;
    margin-top: 250px;
    display: flex;
    justify-content: center;
    align-items: end;
    flex-wrap: wrap;
  }
  .TitlePageTitle {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 800;
    font-size: 72px;
    line-height: 72px;
    letter-spacing: -0.02em;
  }

  .TitlePageText {
    font-family: "Urbanist";
    max-width: 145px;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    margin-top: 6px;
  }

  .TitlePageCase {
    width: 112px;
    background-color: $yellow;
    transform: rotate(-6.1deg);
    text-align: center;
    padding: 6px 8px 6px 6px;
    transform: rotate(-7.37deg);
  }

  .TitlePageSubtitle {
    text-transform: uppercase;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
    letter-spacing: -0.02em;
    border: 2.92871px solid #060403;
    padding: 12px 15px 12px 15px;
  }
}

@media (min-width: 375px) {
  .TitlePageDescriptioncWrapper {
    min-width: 328px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 217px;
    display: flex;
    justify-content: center;
    align-items: end;
    flex-wrap: wrap;
  }
  .TitlePageTitle {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 800;
    font-size: 88px;
    line-height: 88px;
    letter-spacing: -0.02em;
  }

  .TitlePageText {
    font-family: "Urbanist";
    max-width: 180px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    margin-top: 8px;
    margin-right: 0px;
  }

  .TitlePageCase {
    margin-top: 8px;
    width: 138px;
    background-color: $yellow;
    transform: rotate(-6.1deg);
    text-align: center;
    padding: 8px 10px 8px 8px;
    transform: rotate(-7.37deg);
  }

  .TitlePageSubtitle {
    text-transform: uppercase;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 22px;

    border: 2.92871px solid #060403;
    padding: 15px 18px;
  }
}

@media (min-width: 480px) {
  .TitlePageDescriptioncWrapper {
    max-width: 330px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 193px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
}

@media (min-width: 600px) {
  .TitlePageDescriptioncWrapper {
    min-width: 375px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 236px;
    display: flex;
    justify-content: start;
    flex-wrap: wrap;
  }
  .TitlePageTitle {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 800;
    font-size: 97px;
    line-height: 99px;
    letter-spacing: -0.02em;
  }

  .TitlePageText {
    font-family: "Urbanist";
    max-width: 196px;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    margin-top: 8px;
    margin-right: 0px;
    letter-spacing: 0.02em;
  }

  .TitlePageCase {
    width: 177px;
    background-color: $yellow;
    transform: rotate(-6.1deg);
    text-align: center;
    padding: 9px 10px 9px 10px;
    transform: rotate(-7.37deg);
  }

  .TitlePageSubtitle {
    text-transform: uppercase;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 30px;
    border: 2.92871px solid #060403;
    padding: 19px 24px 19px 24px;
  }
}

@media (min-height: 800px) {
  .TitlePageDescriptioncWrapper {
    min-width: 373px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 280px;
    display: flex;
    justify-content: center;
    align-items: end;
    flex-wrap: wrap;
  }
}

@media (min-width: 960px) {
  .TitlePageDescriptioncWrapper {
    min-width: 328px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 236px;
    display: flex;
    justify-content: start;
    align-items: center;
    flex-wrap: wrap;
  }
  .TitlePageTitle {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 800;
    font-size: 86px;
    line-height: 87px;
    letter-spacing: -0.02em;
  }

  .TitlePageText {
    font-family: "Urbanist";
    max-width: 173px;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    margin-top: 12px;
    margin-right: 0px;
  }

  .TitlePageCase {
    margin-top: 8px;
    width: 156px;
    background-color: $yellow;
    transform: rotate(-6.1deg);
    text-align: center;
    padding: 8px 5px 8px 8px;
    transform: rotate(-7.37deg);
  }

  .TitlePageSubtitle {
    text-transform: uppercase;
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 26px;

    border: 2.92871px solid #060403;
    padding: 16px 20px 16px 20px;
  }
}

@media (min-width: 1366px) {
  .TitlePageDescriptioncWrapper {
    max-width: 467px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 189px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  .TitlePageTitle {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 800;
    font-size: 118px;
    line-height: 120px;
    letter-spacing: -0.02em;
    flex: 1 0 100%;
  }

  .TitlePageText {
    max-width: 238px;
    font-family: "Urbanist";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    margin-top: 20px;
    margin-right: 11px;
    letter-spacing: 0.02em;
    color: #383635;
  }

  .TitlePageCase {
    margin-top: 8px;
    width: 214px;
    background-color: $yellow;
    transform: rotate(-6.1deg);
    text-align: center;
    padding: 12px 8px 11px 12px;
    transform: rotate(-7.37deg);
  }

  .TitlePageSubtitle {
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 36px;
    border: 2.92871px solid #060403;
    padding: 15px 22px 16px 22px;
  }
}

@media (min-width: 1920px) {
  .TitlePageDescriptioncWrapper {
    max-width: 530px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 270px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  .TitlePageTitle {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 800;
    font-size: 149px;
    line-height: 152px;
    letter-spacing: -0.02em;
    flex: 1 0 100%;
  }

  .TitlePageText {
    max-width: 280px;
    font-family: "Urbanist";
    font-style: normal;
    font-weight: 500;
    font-size: 23px;
    line-height: 30px;
    margin-top: 20px;
    margin-right: 11px;
    letter-spacing: 0.02em;
    color: #383635;
  }

  .TitlePageCase {
    margin-top: 8px;
    width: 238px;
    background-color: $yellow;
    transform: rotate(-6.1deg);
    text-align: center;
    padding: 11px 12px 11px 12px;
    transform: rotate(-7.37deg);
  }

  .TitlePageSubtitle {
    font-style: normal;
    font-weight: 600;
    font-size: 46px;
    line-height: 46px;
    border: 2.92871px solid #060403;
    padding: 16px 22px 16px 22px;
  }
}

@media (max-height: 600px) {
  .TitlePageDescriptioncWrapper {
    margin-left: auto;
    margin-right: auto;
    margin-top: 160px;
    display: flex;
    justify-content: center;
    align-items: end;
    flex-wrap: wrap;
  }
}
