@import "../../../../../App.module.scss";

@keyframes textAnimation {
  0% {
    opacity: 0;
    left: 200px;
  }
  100% {
    opacity: 1;
    left: 0px;
  }
}
@keyframes textAnimationOff {
  0% {
  }
  100% {
    opacity: 0;
  }
}

// @keyframes discoveryAnimation {
//   0% {
//     height: 0px;
//   }
//   100% {
//     height: 84px;
//   }
// }

// @keyframes designAnimation {
//   0% {
//     height: 0px;
//   }
//   100% {
//     height: 190px;
//   }
// }

// @keyframes deliveryAnimation {
//   0% {
//     height: 0px;
//   }
//   100% {
//     height: 296px;
//   }
// }
.fullCycle {
  animation: textAnimation 2s 0s forwards;
}
.fullCycleHide {
  animation: textAnimationOff 2s 0s forwards;
}
.fullCycleWrapper {
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  padding-right: 20px;
}

.fullCycleDescription {
  position: relative;
}

.fullCycleDescriptionTitle {
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  color: $black;
  margin-bottom: 8px;
}

.fullCycleDescriptionText {
  max-width: 1066px;
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: $greyLight;
  letter-spacing: 0.02em;
}

.fullCycleBlocks {
  text-align: center;
  position: relative;
  animation: textAnimation;
  animation-duration: 1s;
}

.fullCycleBlocksItem {
  margin-right: 0px;
  &:last-child {
    margin-right: 0px;
  }
  margin-bottom: 20px;
}

.fullCycleBlocksTitle {
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-transform: capitalize;
  color: $black;
  margin-bottom: 16px;
}

.fullCycleBlocksBlocksItemBlock {
  width: 1005;
  height: 19px;
  margin-bottom: 4px;
  border-radius: 8px;
  &:last-child {
    margin-bottom: 0px;
  }
  background-color: #d2d628;
}

.fullCircleBlock {
  background-image: url(../../../../../assets/FullCycle.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  animation: textAnimation;
  animation-duration: 1s;
  width: 100%;
  min-height: 134px;
  margin-top: 20px;
}

@media (max-height: 600px) {
  .fullCycleDescriptionText {
    max-width: 1066px;
    font-family: "Urbanist";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: $greyLight;
    letter-spacing: 0.02em;
  }
  .fullCircleBlock {
    position: relative;
    animation: textAnimation;
    animation-duration: 1s;
    width: 100%;
    min-height: 134px;
    margin-top: 10px;
  }
}
@media (min-width: 660px) {
  .fullCircleBlock {
    position: relative;
    animation: textAnimation;
    animation-duration: 1s;
    width: 100%;
    min-height: 233px;
    margin-top: 24px;
  }
}
@media (min-width: 1150px) {
  .fullCycleBlocksItem {
    margin-bottom: 0px;
  }
  .fullCycleBlocks {
    display: flex;
    align-items: end;
    text-align: center;
    flex-wrap: nowrap;
    justify-content: start;
  }
  .fullCycleBlocksItem {
    margin-right: 20px;
    &:last-child {
      margin-right: 0px;
    }
    margin-bottom: 20px;
  }
  .fullCycleBlocksBlocksItemBlock {
    width: 342px;
    height: 19px;
    margin-bottom: 4px;
    border-radius: 8px;
    &:last-child {
      margin-bottom: 0px;
    }
    background-color: #d2d628;
  }
}

@media (min-width: 1366px) {
  .fullCycleBlocksItem {
    margin-bottom: 0px;
  }
  .fullCycleBlocks {
    display: flex;
    align-items: end;
    text-align: center;
    flex-wrap: nowrap;
    justify-content: start;
  }
  .fullCycleBlocksItem {
    margin-right: 20px;
    &:last-child {
      margin-right: 0px;
    }
    margin-bottom: 20px;
  }
  .fullCycleBlocksBlocksItemBlock {
    width: 342px;
    height: 19px;
    margin-bottom: 4px;
    border-radius: 8px;
    &:last-child {
      margin-bottom: 0px;
    }
    background-color: #d2d628;
  }
  .fullCycleDescriptionText {
    min-width: 340px;
    max-width: 1066px;
    font-family: "Urbanist";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: $greyLight;
    letter-spacing: 0.02em;
    margin-bottom: 26px;
    min-height: auto;
  }
}
