@import "../../../App.module.scss";
.portfolio {
  // background-image: url(../../../assets/portfolioBackground.svg);
  // background-position: center;
  // background-repeat: no-repeat;
  // background-size: cover;
  position: relative;
  margin-top: 100px;
  display: flex;
  justify-content: center;
}

.portfolioBackground {
  width: 1366px;
  height: 1500px;
  position: absolute;
  z-index: 2;
}
@media (min-width: 308px) {
  .portfolioBackground {
    width: 1366px;
    height: 1550px;
    position: absolute;
  }
}
.portfolioContentWrapper {
  width: 100%;
}
.portfolioHeader {
  // background-color: $lightYellow;
  padding: 70px 20px 0px;
  position: relative;
  z-index: 2;
}
.portfolioHeaderTitle {
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: $grey;
  margin-bottom: 8px;
}

.portfolioHeaderSubTitle {
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 800;
  font-size: 40px;
  line-height: 48px;
  letter-spacing: -0.02em;
  color: $black;
}

.portfolioWorks {
  padding: 36px 20px 0px;
  position: relative;
  z-index: 2;
  // background-color: $lightYellow;
}
.portfolioWorksProject {
  width: 100%;
  height: 236px;
  background-position: center;
  background-size: cover;
  border-radius: 1000px;
  margin-bottom: 40px;
  &:last-child {
    margin-bottom: 0px;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.15s;
}
.portfolioWorksProjectActive {
  width: 100%;
  height: 236px;
  background-position: center;
  background-size: cover;
  border-radius: 1000px;
  margin-bottom: 40px;
  &:last-child {
    margin-bottom: 0px;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #060403;
  box-shadow: inset -5px -23px 0px #000000;
  border-radius: 110px;
}
.portfolioWorksMobileProjectActive {
  width: 100%;
  height: 236px;
  background-position: center;
  background-size: cover;
  border-radius: 1000px;
  margin-bottom: 16px;
  &:last-child {
    margin-bottom: 0px;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #060403;
  border-radius: 110px;
}

.portfolioWorksProjectButton {
  padding: 15px 34px 15px 32px;
  background-color: $black;
  color: $white;
  border-radius: 110px;
  display: flex;
  align-items: center;
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.02em;
  & :last-child {
    margin-left: 42px;
  }
  cursor: pointer;
  border: none;
}
.portfolioWorksProjectButtonActive {
  padding: 15px 34px 15px 32px;
  background-color: $black;
  color: $white;
  border-radius: 110px;
  display: flex;
  align-items: center;
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.02em;
  & :last-child {
    margin-left: 42px;
  }
  border: none;
}

.portfolioWorksProjectButton svg {
  overflow: visible;
}
.portfolioWorksProjectButtonActive svg {
  overflow: visible;
}

.portfolioWorksProject1 {
  background-image: url(../../../assets/work1.png);
}
.portfolioWorksProject2 {
  background-image: url(../../../assets/work2.png);
}
.portfolioWorksProject3 {
  background-image: url(../../../assets/work3.png);
}
.portfolioWorksProject4 {
  background-image: url(../../../assets/work4.png);
}

.portfolioFooter {
  // background-color: $lightYellow;
  text-align: center;
  padding: 0px 20px 95px;
  background-color: #7d77b7;
}
.portfolioFooterText {
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: $black;
  margin-bottom: 24px;
  margin-top: 60px;
  position: relative;
  z-index: 2;
}

.portfolioFooterButton {
  min-width: 335px;
  max-width: 335px;
  padding: 14px 0px;
  border-radius: 100px;
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: white;
  background-color: $black;
  cursor: pointer;
  border: none;
  position: relative;
  z-index: 2;
}

@media (max-width: 374px) {
  .portfolioFooterButton {
    min-width: auto;
    width: 100%;
  }
  .portfolioWorksProjectButton {
    padding: 15px 34px 15px 32px;
    background-color: $black;
    color: $white;
    border-radius: 110px;
    display: flex;
    align-items: center;
    font-family: "Urbanist";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.02em;
    & :last-child {
      margin-left: 30px;
    }
    cursor: pointer;
    border: none;
  }
}
@media (min-width: 400px) {
  .portfolioWorksProject {
    width: 100%;
    height: 236px;
    background-position: center;
    background-size: cover;
    border-radius: 1000px;
    margin-bottom: 40px;
    &:last-child {
      margin-bottom: 0px;
    }
    display: flex;
    justify-content: start;
    align-items: center;
    padding-left: 12%;
  }
  .portfolioWorksProjectActive {
    width: 100%;
    height: 236px;
    background-position: center;
    background-size: cover;
    border-radius: 1000px;
    margin-bottom: 40px;
    &:last-child {
      margin-bottom: 0px;
    }
    display: flex;
    justify-content: start;
    align-items: center;
    border: 2px solid #060403;
    box-shadow: inset -5px -23px 0px #000000;
    border-radius: 110px;
    padding-left: 12%;
  }
  .portfolioWorksMobileProjectActive {
    width: 100%;
    height: 236px;
    background-position: center;
    background-size: cover;
    border-radius: 1000px;
    margin-bottom: 40px;
    &:last-child {
      margin-bottom: 0px;
    }
    display: flex;
    justify-content: start;
    align-items: center;
    border: 2px solid #060403;
    box-shadow: inset -5px -23px 0px #000000;
    border-radius: 110px;
    padding-left: 2%;
  }
}

@media (min-width: 1366px) {
  .portfolioBackground {
    width: 100%;
    height: 100%;
    position: absolute;
  }
  .portfolioHeader {
    padding: 137px 20px 0px 150px;
    position: relative;
  }
  .portfolioHeaderTitle {
    font-family: "Urbanist";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: $grey;
    margin-bottom: 8px;
  }

  .portfolioHeaderSubTitle {
    font-family: "Urbanist";
    font-style: normal;
    font-weight: 800;
    font-size: 60px;
    line-height: 60px;
    letter-spacing: -0.02em;
    color: $black;
  }

  .portfolioWorks {
    padding: 50px 40px 0px;
    // background-color: $lightYellow;
    position: relative;
  }
  .portfolioWorksProject {
    width: 100%;
    height: 407px;
    background-position: center;
    background-size: cover;
    border-radius: 1000px;
    margin-bottom: 40px;
    &:last-child {
      margin-bottom: 0px;
    }
    display: flex;
    justify-content: start;
    align-items: center;
    padding-left: 12%;
    &:hover {
      border: 2px solid #060403;
      box-shadow: inset -13px -40px 0px #000000;
      border-radius: 1000px;
      padding-left: 12%;
    }
    &:hover .portfolioWorksProjectButton {
      padding: 15px 34px 15px 32px;
      background-color: $black;
      color: $white;
      border-radius: 110px;
      display: flex;
      align-items: center;
      font-family: "Urbanist";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.02em;
      & :last-child {
        margin-left: 42px;
      }
    }
  }
  .portfolioWorksProjectButton {
    padding: 15px 34px 15px 32px;
    background-color: $black;
    color: $white;
    border-radius: 110px;
    display: flex;
    align-items: center;
    font-family: "Urbanist";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.02em;
    & :last-child {
      margin-left: 42px;
    }
    display: none;
  }
  .portfolioWorksProjectButtonActive {
    padding: 15px 34px 15px 32px;
    background-color: $black;
    color: $white;
    border-radius: 110px;
    display: flex;
    align-items: center;
    font-family: "Urbanist";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.02em;
    & :last-child {
      margin-left: 42px;
    }
  }
  .portfolioFooter {
    // background-color: $lightYellow;
    text-align: center;
    padding: 96px 210px 120px;
    background-color: #7d77b7;
  }
  .portfolioFooterText {
    font-family: "Urbanist";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: $black;
    margin-bottom: 24px;
    position: relative;
  }

  .portfolioFooterButton {
    min-width: auto;
    width: auto;
    padding: 14px 42px;
    border-radius: 100px;
    font-family: "Urbanist";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.02em;
    color: white;
    background-color: $black;
    position: relative;
    border: 2px solid rgba(6, 4, 3, 1);
    &:hover {
      background-color: rgba(255, 255, 255, 1);
      border: 2px solid rgba(6, 4, 3, 1);
      color: rgba(6, 4, 3, 1);
    }
  }
}
