@import "../../../App.module.scss";

@keyframes checkMarkAnimation {
  0% {
    transform: rotate(-90deg);
  }
  50% {
  }
  100% {
    transform: none;
  }
}

@keyframes spawnAnimation {
  0% {
    top: -20px;
  }

  100% {
    top: 0;
  }
}

.Congratulations {
  max-width: 269px;
  min-height: 209px;
  margin-top: 126px;
  position: relative;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  flex-direction: column;
  animation: onOpacityAnimation 1s 0s 1, spawnAnimation 1s 0s 1;
}

.CongratulationsButton {
  width: 105px;
  height: 105px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  margin-bottom: 26px;
  overflow: hidden;
  animation: checkMarkAnimation;
  animation-duration: 1s;
}

.checkMark {
  width: 105px;
  height: 105px;
  margin-right: auto;
  margin-left: auto;
}

.CongratulationsTitle {
  font-family: "Urbanist";
  margin-bottom: 8px;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
}

.CongratulationsText {
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}

@media (min-width: 1366px) {
  .br {
    display: none;
  }
  .Congratulations {
    max-width: 375px;
  }
  .CongratulationsTitle {
    font-family: "Urbanist";
    margin-bottom: 8px;
    font-weight: 700;
    font-size: 30px;
    line-height: 40px;
  }
  .CongratulationsText {
    font-family: "Urbanist";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
  }
}
