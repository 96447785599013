@import "../../../App.module.scss";

.portfolioProjectsMain {
  padding: 70px 20px 0px 20px;
  margin-bottom: 200px;
  background-color: rgba(229, 228, 241, 1);
  position: relative;
  z-index: 1;
}
.backgroundTheme {
  position: absolute;
  width: 100vw;
  height: 100%;
  background-color: rgba(229, 228, 241, 1);
  z-index: 0;
}

.mobailPortfolioProjectsHeaderBackground {
  width: 100%;
  height: 60px;
  position: fixed;
  z-index: 10;
  background-color: rgb(229, 228, 241);
  display: flex;
  justify-content: flex-end;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-right: 20px;
  box-shadow: 0 4px 5px 0px rgba(0, 0, 0, 0.25);
}

.mobailPortfolioProjectsHeaderContactUsButton {
  padding: 0px 32px;
  font-family: "Urbanist";
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 2%;
  color: white;
  border-radius: 100px;
  background-color: black;
  border: none;
}
.crossIcon {
  position: absolute;
  top: 24px;
  right: 25px;
  cursor: pointer;
}
.buttonCase {
  padding-left: 8px;
  display: flex;
  & :first-child {
    margin-right: 16px;
  }
  &:hover :first-child {
    fill: rgba(101, 96, 147, 1);
  }
  margin-bottom: 14px;
  cursor: pointer;
  align-items: center;
}
.portfolioProjectsButtonCaseText {
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: $grey;
  &:hover {
    color: rgba(101, 96, 147, 1);
  }
}
.portfolioProjectsContentWrapper {
  display: flex;
  flex-direction: column;

  margin-right: auto;
  margin-left: auto;
  max-width: 1066px;
}
.portfolioProjectsTitle {
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 800;
  font-size: 40px;
  line-height: 48px;
  letter-spacing: -0.02em;
  color: black;
  margin-bottom: 32px;
}

.portfolioProjectsText {
  font-family: Urbanist;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 2%;
  margin-bottom: 32px;
}
.portfolioProjectsTextProject2 {
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: rgba(56, 54, 53, 1);
  margin-bottom: 20px;
  &:first-of-type {
    margin-bottom: 0px;
  }
}
.portfolioProjectsOutlineText {
  font-family: Urbanist;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 2%;
}
.portfolioProjectsCenterHeader {
  font-family: Urbanist;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 2%;
  text-align: center;
  margin-top: 72px;
}
.portfolioProjectsImgWrapper {
  text-align: center;
  border-radius: 4px;
  border: 2px solid rgba(125, 119, 183, 1);
  border-radius: 8px;
  margin-bottom: 56px;
}
.portfolioProjectsImg {
  width: 100%;
  height: 100%;
  margin-bottom: 56px;
  margin-top: 32px;
}
.portfolioProjects3Img {
  width: 100%;
  height: 100%;
  margin-bottom: 24px;
}
.portfolioProjects3CaseImg {
  width: 100%;
  height: 100%;
  margin-bottom: 56px;
  margin-top: 72px;
  margin-bottom: 24px;
}
.portfolioProjectsDoubleImgCase {
  display: flex;
  flex-direction: column;
  margin-top: 64px;
}
.portfolioProjectsDoubleImgCase .portfolioProjectsImg {
  margin-top: 0px;
}
.portfolioProjectsDoubleImgCase .portfolioProjects3CaseImg {
  margin-top: 0px;
}
.portfolioProjectsDoubleImgCase .portfolioProjectsImg:first-of-type {
  margin-bottom: 24px;
}
.portfolioProjectsFullScreenImg {
  width: 100vw;
  margin-left: -20px;
  margin-top: 48px;
  margin-bottom: 48px;
}
.portfolioProjects2CaseImg {
  max-width: 100%;
  max-height: 100%;
  margin-bottom: 24px;
}
.portfolioProjectsSectionHeader {
  font-family: Urbanist;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 2%;
  margin-bottom: 20px;
}

.portfolioProjectsSection {
  // margin-left: 20px;
  margin-bottom: 50px;
}
@media (max-width: 982px) {
  .portfolioProjectsSectionNumber {
    display: none;
  }
}

.portfolioProjectsSectionElement {
  font-family: Urbanist;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 2%;
  color: rgba(106, 104, 104, 1);
}

.portfolioProjectsFooter {
  padding-top: 250px;
  padding-bottom: 20px;
  background-image: url(../../../assets/PortfolioProjectsFooterNewBackground.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
  z-index: 1;
  background-color: rgba(229, 228, 241, 1);
}
.footerButtonsCase {
  display: flex;
  flex-direction: column-reverse;
  align-self: flex-end;
  margin-bottom: 75px;
  padding-left: 20px;
  position: absolute;
  top: -120px;
}
.footerButtonCase {
  margin-right: 20px;
  display: grid;
}
.footerBackButtonCase {
  margin-right: 20px;
  display: grid;
}
.portfolioProjectsFooterButton {
  padding: 14px 32px 14px 32px;
  background: rgba(177, 173, 212, 1);
  border-radius: 100px;
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: $black;
  text-align: start;
  // & :last-child {
  //   margin-left: 134px;
  // }
  margin-bottom: 8px;
  border: 1.5px solid rgba(6, 4, 3, 1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 327px;
  &:hover {
    background-color: rgba(151, 146, 197, 1);
  }
}

.portfolioProjectsFooterButton svg path {
  margin-left: 36px;
  fill: #060403;
}

.portfolioProjectsFooterButton svg {
  overflow: visible;
  margin-left: 36px;
}

.portfolioProjectsFooterBackButton svg {
  overflow: visible;
  margin-right: 36px;
}
.portfolioProjectsFooterBackButton {
  padding: 14px 32px 14px 32px;
  background: rgba(177, 173, 212, 1);
  border: none;
  border-radius: 100px;
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: $black;
  // & :first-child {
  //   margin-right: 134px;
  // }
  margin-bottom: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 327px;
  &:hover {
    background-color: rgba(151, 146, 197, 1);
  }
}

@media (max-width: 375px) {
  .portfolioProjectsFooterButton {
    font-size: 14px;
    line-height: 20px;
    min-width: auto;
  }

  .portfolioProjectsFooterBackButton {
    font-size: 14px;
    line-height: 20px;
    min-width: auto;
  }
}

.portfolioProjectsContactUsButtonCase {
  display: flex;
}

.portfolioProjectsContactUsButton {
  padding: 14px 60px 14px 60px;
  background-color: $black;
  color: $white;
  border-radius: 100px;
  border: 2px solid rgba(6, 4, 3, 1);
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.02em;
  margin-bottom: 65px;
  cursor: pointer;
  transition: 1s;
  &:hover {
    color: rgba(6, 4, 3, 1);
    background-color: rgba(255, 255, 255, 1);
    border: 2px solid rgba(6, 4, 3, 1);
  }
}

.footerInfoLink {
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #000000;
  text-decoration: none;
}

.footerRight {
  display: none;
  color: rgba(54, 50, 75, 1);
}
.footerLinksCase {
  text-align: center;
  margin-top: 64px;
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: $grey;
  margin-bottom: 42px;
}

.footerLink {
  margin-right: 16px;
  &:last-of-type {
    margin-right: 0px;
  }
  &:hover svg path {
    fill: rgba(190, 192, 36, 1);
  }
}
.footerMobailRight {
  font-family: "Urbanist";
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: rgba(54, 50, 75, 1);
}
@media (min-width: 982px) {
  .portfolioProjectsMain {
    padding: 121px 150px 0px 150px;
  }
  .crossIcon {
    display: none;
    position: absolute;
    top: 24px;
    right: 25px;
    cursor: pointer;
  }
  .buttonCase {
    padding-left: 8px;
    display: flex;
    & :first-child {
      margin-right: 16px;
    }
    margin-bottom: 14px;
  }
  .portfolioProjectsButtonCaseText {
    font-family: "Urbanist";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: $grey;
  }

  .portfolioProjectsTitle {
    font-family: "Urbanist";
    font-style: normal;
    font-weight: 800;
    font-size: 60px;
    line-height: 72px;
    letter-spacing: -0.02em;
    color: black;
    margin-bottom: 40px;
  }
  .portfolioProjectsText {
    font-family: "Urbanist";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: $greyLight;
  }
  .portfolioProjectsTextProject2 {
    font-family: "Urbanist";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: rgba(56, 54, 53, 1);
    margin-bottom: 20px;
  }
  .portfolioProjectsSectionCase {
    display: flex;
  }
  .portfolioProjectsSectionNumber {
    font-family: Urbanist;
    font-weight: 700;
    font-size: 114px;
    line-height: 92px;
    letter-spacing: 2%;
    margin-right: 20px;
  }
  .portfolioProjectsDoubleImgCase {
    display: flex;
    flex-direction: row;
  }

  .portfolioProjectsDoubleImgCase .portfolioProjectsImg:first-of-type {
    margin-bottom: 24px;
    margin-right: 25px;
  }
  .portfolioProjects3CaseImg {
    max-width: 300px;
    max-height: 500px;
    margin-right: 24px;
    &:last-child {
      margin-right: 24px;
    }
  }
  .portfolioProjectsFullScreenImg {
    width: 100vw;
    margin-left: -150px;
  }

  .portfolioProjects3Img {
    width: 100%;
    height: 100%;
    margin-bottom: 56px;
    margin-top: 50px;
    margin-right: 25px;
    &:last-child {
      margin-right: 0px;
    }
  }

  .portfolioProjects2CaseImg {
    max-width: 700px;
    max-height: 700px;
    margin-right: 24px;
    &:last-child {
      margin-right: 24px;
    }
  }
  .portfolioProjectsFooter {
    padding-top: 200px;
    background-position: top;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .footerButtonsCase {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-self: auto;
    margin-bottom: 75px;
  }
  .footerButtonCase {
    align-self: flex-end;
    margin-right: 20px;
  }
  .footerBackButtonCase {
    align-self: flex-end;
    margin-right: 20px;
  }
  .portfolioProjectsFooterButton {
    padding: 15px 34px 15px 32px;
    background: rgba(177, 173, 212, 1);
    border-radius: 100px;
    font-family: "Urbanist";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.02em;
    color: $black;
    & :last-child {
      margin-left: 134px;
    }
    margin-bottom: 0px;
    border: 1.5px solid rgba(6, 4, 3, 1);
  }

  .portfolioProjectsFooterBackButton {
    padding: 15px 34px 15px 32px;
    background: rgba(177, 173, 212, 1);
    border: none;
    border-radius: 100px;
    font-family: "Urbanist";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.02em;
    color: $black;
    & :first-child {
      margin-right: 134px;
    }
    margin-bottom: 0px;
  }

  .portfolioProjectsContactUsButtonCase {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-right: 100px;
  }
  .footerInfoCase {
    padding-left: 306px;
    padding-right: 50px;
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
  .footerInfoLink {
    font-family: "Urbanist";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 18px;
    letter-spacing: 0.02em;
    color: #000000;
    text-decoration: none;
  }
  .footermailRightsBlock {
    text-align: end;
  }
  .footerRight {
    display: block;
  }
  .footerLinksCase {
    display: flex;
    align-items: center;
    text-align: center;
    margin-top: 0px;
    font-family: "Urbanist";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.02em;
    color: $grey;
    & :first-child {
      margin-bottom: 0px;
    }
    margin-bottom: 0px;
  }

  .footerLink {
    margin-right: 16px;
    &:last-of-type {
      margin-right: 16px;
    }
  }
  .footerMobailRight {
    display: none;
    color: rgba(54, 50, 75, 1);
  }
}

@media (min-width: 1060px) {
  .portfolioProjects3CaseImg {
    max-width: 600px;
    max-height: 600px;
    margin-right: 24px;
    &:last-child {
      margin-right: 24px;
    }
  }
}
@media (min-width: 1366px) {
  .portfolioProjectsTitle {
    font-family: "Urbanist";
    font-style: normal;
    font-weight: 800;
    font-size: 60px;
    line-height: 72px;
    letter-spacing: -0.02em;
    color: black;
    margin-bottom: 40px;
  }
  .portfolioProjectsFullScreenImg {
    width: 100%;
    margin-left: auto;
    margin-top: 72px;
    margin-bottom: 72px;
  }
  .portfolioProjects3CaseImg {
    max-width: 800px;
    max-height: 800px;
    margin-right: 24px;
    &:last-child {
      margin-right: 24px;
    }
  }
  .portfolioProjects2CaseImg {
    max-width: 518px;
    max-height: 1000px;
    margin-right: 24px;
    &:last-child {
      margin-right: 24px;
    }
  }
}
@media (min-width: 1700px) {
  .portfolioProjectsFooter {
    padding-top: 200px;
    padding-left: 100px;
    background-position: top;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}

// .textIMGWrapper {
//   max-height: 400px; /* Задайте желаемую максимальную высоту для блока */
//   overflow-y: auto;
// }
// .textIMGBox {
//   display: flex;
//   flex-direction: column;
//   max-height: 100%; /* Установите максимальную высоту для контента */
//   // overflow: hidden; /* Разрешите вертикальную прокрутку внутри блока */
// }

// .textIMG {
//   max-height: none;
//   width: auto; /* Может понадобиться настроить ширину картинки */
// }
