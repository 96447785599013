$violet: #7d77b7;
$yellow: #d2d628;
$lightYellow: #dbde53;
$white: #ffffff;
$black: #060403;
$orange: #e45929;
$grey: #383635;
$greyLight: #6a6868;
$greyWhite: rgba(255, 255, 255, 0.8);
$darkWhite: rgba(247, 243, 212);
$greyDark: #181818;

@keyframes topRightCircleAnimation {
  0% {
  }
  20% {
    z-index: 2;
  }
  100% {
    transform: scale(5);
    z-index: 2;
  }
}

@keyframes topRightCircleAnimationReverse {
  0% {
    transform: scale(5);
    z-index: 2;
  }
  20% {
    z-index: 2;
  }
  100% {
    transform: scale(1);
    z-index: 2;
  }
}

@keyframes offOpacityAnimation {
  0% {
  }
  100% {
    opacity: 0;
  }
}

@keyframes topLeftFullCircleAnimation {
  0% {
    z-index: 2;
  }
  100% {
    top: 0;
    left: 300px;
    opacity: 0;
  }
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.1, 1.1);
  }
  100% {
    transform: scale(1, 1);
  }
}

@keyframes zoom-out-zoom-in {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes navLinksAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes circleAnimation {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes circleBackAnimation {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes onOpacityAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
@keyframes offOpacityAnimation {
  0% {
  }
  100% {
    opacity: 0;
  }
}

@keyframes mainPageWrapperAnimation {
  0% {
    top: 1000px;
  }

  100% {
    top: 0;
  }
}
@keyframes mainPageWrapperAnimationOff {
  0% {
    top: 0;
  }
  100% {
    opacity: 0;
    top: 1000px;
  }
}
@keyframes mainPageWrapperFromContactAnimation {
  0% {
    top: -1000px;
  }

  100% {
    top: 0;
  }
}

@keyframes mainPageWrapperToContactAnimation {
  0% {
    top: 0;
  }

  100% {
    top: -1500px;
    opacity: 0;
  }
}
@keyframes footerPageWrapperAnimation {
  0% {
    top: 1000px;
    opacity: 0;
  }

  100% {
    top: 0;
  }
}
@keyframes footerPageWrapperAnimationOff {
  0% {
    top: 0;
  }
  100% {
    opacity: 0;
    top: 1000px;
  }
}

@keyframes offModalWindowAnimation {
  0% {
    opacity: 1;
  }
  99% {
    opacity: 0;
    top: 1000px;
  }
  100% {
    opacity: 0;
    top: -2000px;
  }
}
.serviceWrapper {
  animation: onOpacityAnimation 1s 0s forwards;
}
.serviceWrapperHide {
  animation: offOpacityAnimation 1s 0s forwards;
}

.mainPageWrapper {
  position: relative;
  animation: mainPageWrapperAnimation 1s 0s forwards;
  z-index: 1;
}

.mainPageWrapperHide {
  position: relative;
  animation: mainPageWrapperAnimationOff 1s 0s forwards;
}

.mainPageWrapperFromContact {
  position: relative;
  animation: mainPageWrapperFromContactAnimation 1s 0s forwards;
}

.mainPageWrapperToContact {
  position: relative;
  animation: mainPageWrapperToContactAnimation 1s 0s forwards;
}

.footerPageWrapper {
  position: relative;
  animation: footerPageWrapperAnimation 1s 0s forwards;
}

.footerPageWrapperHide {
  position: relative;
  animation: footerPageWrapperAnimationOff 1s 0s forwards;
}
.app {
  background-color: #f4eee2;
}
.mainWrapper {
  animation: onOpacityAnimation 1s 0s forwards;
}
.mainWrapperHide {
  animation: offOpacityAnimation 1s 0s forwards;
}
.appWrapper {
  width: 100%;
  min-height: 100vh;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
}

.modalWindowWrapperHide {
  position: fixed;
  z-index: 7;
  width: 100%;
  height: 100%;
  top: -2000px;
}
.modalWindowWrapper {
  position: fixed;
  z-index: 7;
  width: 100%;
  height: 100%;
  animation: offModalWindowAnimation 1s 1s forwards;
}
.modalWindowWrapperOpen {
  position: fixed;
  z-index: 7;
  width: 100%;
  height: 100%;
}
.menuButton,
.openMenuButton {
  width: 40px;
  height: 40px;
  position: fixed;
  z-index: 20;
  top: 13px;
  right: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  // transition: all 1s ease-in-out;
  overflow: hidden;
}

.modalBurgerButton,
.openModalBurgerButton {
  width: 30px;
  height: 3px;
  background-color: $black;
  position: absolute;
  border: none;
  cursor: pointer;
  transition: all 1s ease-in-out;
  z-index: 20;
  &::before,
  &::after {
    content: "";
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    width: 30px;
    height: 3px;
    background-color: $black;
    transition: all 1s ease-in-out;
    left: 0;
    top: 0;
    z-index: 20;
  }
}
.modalBurgerButton::before {
  transform: translateY((-10px));
}
.modalBurgerButton::after {
  transform: translateY((10px));
}
.openModalBurgerButton {
  transform: translateX(-50px);
  background-color: transparent;
}
.openModalBurgerButton::before {
  transform: rotate(45deg) translate(35px, -35px);
}
.openModalBurgerButton::after {
  transform: rotate(-45deg) translate(35px, 35px);
}
.openBackgroundMenu {
  width: 1500px;
  height: 1500px;
  border-radius: 50%;
  background-color: #dbde53;
  position: fixed;
  top: -1500px;
  right: -750px;
  animation: topRightCircleAnimation 3s 0s forwards;
  z-index: 15;
}
.backgroundMenu {
  width: 1500px;
  height: 1500px;
  border-radius: 50%;
  background-color: #dbde53;
  position: absolute;
  top: -1500px;
  right: -750px;
  animation: topRightCircleAnimationReverse 2s 0s forwards;
  z-index: 15;
}
.openMenuNavWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.menuNavWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.menuNav {
  display: flex;
  flex-direction: column;
  z-index: 7;
  align-items: center;
  margin-top: 100px;
  transition: 0.7s;
  opacity: 0;
  pointer-events: none;
  text-align: center;
}
.openMenuNav {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 7;
  margin-top: 100px;
  transition: 0.5s 1s ease-in;
  text-align: center;
}

.navItem,
.openNavItem {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 66px;
  line-height: 24px;
  color: $greyDark;
  margin-bottom: 80px;
  & .a {
    text-decoration: none;
  }
  &:nth-of-type(3) {
    margin-bottom: 110px;
  }
  cursor: pointer;
}
@media (min-width: 1366px) {
  .menuNav {
    display: flex;
    flex-direction: column;
    z-index: 7;
    transition: 0.7s;
    opacity: 0;
    pointer-events: none;
    text-align: center;
  }
  .openMenuNav {
    display: flex;
    flex-direction: column;
    z-index: 7;
    transition: 0.5s 1s ease-in;
    text-align: center;
  }
  .navItem,
  .openNavItem {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 86px;
    line-height: 24px;
    color: $greyDark;
    margin-bottom: 124px;
    & .a {
      text-decoration: none;
    }
    cursor: pointer;
  }
}

@media (min-width: 1600px) {
  .navItem,
  .openNavItem {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 100px;
    line-height: 24px;
    color: $greyDark;
    margin-bottom: 140px;
    & .a {
      text-decoration: none;
    }
    cursor: pointer;
  }
}

.navItemLink {
  text-decoration: none;
  color: $greyDark;
  &:hover {
    color: rgba(101, 96, 147, 1);
  }
  &:focus {
    outline: none;
  }
}

.navSocialLinks {
  max-width: 136px;
  z-index: 7;
  display: flex;
  & a {
    margin-right: 32px;
  }
  & a :last-child {
    margin-right: 0px;
  }
  transition: 0.7s;
  opacity: 0;
}

.openNavSocialLinks {
  max-width: 136px;
  z-index: 7;
  display: flex;
  & a {
    margin-right: 32px;
    &:hover svg path {
      fill: rgba(101, 96, 147, 1);
    }
  }
  & a:last-child {
    margin-right: 0px;
  }
  transition: 0.5s 1s ease-in;
}

.contactUsButton {
  width: 130px;
  height: 130px;
  background-color: $black;
  border-radius: 100px;
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.02em;
  border: none;
  position: fixed;
  color: #ffffff;
  cursor: pointer;
  z-index: 7;
  bottom: 25px;
  right: 25px;
  animation: zoom-in-zoom-out 2s 0s infinite;
  &:hover {
    background-color: rgba(255, 255, 255, 1);
    border: 2px solid rgba(6, 4, 3, 1);
    color: rgba(6, 4, 3, 1);
  }
}

.titleHeaderBoxWrapper {
  height: 100vh;
  position: relative;
  animation: onnOpacityAnimation 1s 0s forwards;
  background-color: #ece3ce;
}
.titleHeaderBoxWrapperHide {
  height: 100vh;
  position: relative;
  animation: offOpacityAnimation 1s 0s forwards;
  background-color: #ece3ce;
}

.fixDiv {
  position: relative;
  z-index: 5;
  padding: 22px 28px;
  top: 0;
  left: 0;
}
.topLeftBgCircle {
  top: -40px;
  left: -25px;
  z-index: 1;
  width: 59%;
  height: 40%;
  position: absolute;
  animation: zoom-in-zoom-out 3s 0s infinite,
    offOpacityAnimation 0.1s 2.1s forwards;
}
.topLeftBgCircleVisited {
  top: -40px;
  left: -25px;
  z-index: 1;
  width: 59%;
  height: 40%;
  position: absolute;
  animation: circleAnimation 1s 0s forwards, zoom-in-zoom-out 3s 1s infinite;
  transform-origin: top left;
}
.topLeftBgCircleVisitedOff {
  top: -40px;
  left: -25px;
  z-index: 1;
  width: 59%;
  height: 40%;
  position: absolute;
  animation: circleBackAnimation 1s 0s forwards;
  transform-origin: top left;
}
.topLeftDesktopVisitedBgCircle {
  display: none;
}
.topLeftBgFullCircle {
  top: -610px;
  left: -720px;
  z-index: 0;
  width: 900px;
  height: 900px;
  border-radius: 50%;
  background-color: $violet;
  position: absolute;
  animation: topLeftFullCircleAnimation 1.5s 2s forwards;
  transition-timing-function: ease-out;
}

.bottomLeftBgCircle {
  left: -16px;
  bottom: 0;
  z-index: 1;
  width: 40%;
  height: 33%;
  position: absolute;
  animation: zoom-in-zoom-out 3s 0s infinite,
    offOpacityAnimation 1s 2.2s forwards;
}
.bottomLeftVisitedBgCircle {
  left: -16px;
  bottom: 0;
  z-index: 1;
  width: 40%;
  height: 33%;
  position: absolute;
  animation: circleAnimation 1s 0s forwards, zoom-in-zoom-out 3s 1s infinite;
  transform-origin: bottom left;
}
.bottomLeftVisitedBgCircleOff {
  left: -16px;
  bottom: 0;
  z-index: 1;
  width: 40%;
  height: 33%;
  position: absolute;
  animation: circleBackAnimation 1s 0s forwards;
  transform-origin: bottom left;
}
.BottomLeftDesktopVisitedBgCircle {
  display: none;
}

.topRightBgCircle {
  top: -320px;
  right: -429px;
  z-index: 1;
  width: 600px;
  height: 600px;
  border-radius: 50%;
  background-color: $violet;
  position: absolute;
  animation: topRightCircleAnimation 3s 2s forwards, zoom-out-zoom-in 2s 1s 1;
  transition-timing-function: linear;
}

.topRightBgCircleVisited {
  top: -320px;
  right: -429px;
  z-index: 1;
  width: 600px;
  height: 600px;
  border-radius: 50%;
  background-color: $violet;
  position: absolute;
  animation: circleAnimation 1s 0s forwards, zoom-in-zoom-out 3s 1s infinite;
}

.topRightBgCircleVisitedOff {
  top: -320px;
  right: -429px;
  z-index: 1;
  width: 600px;
  height: 600px;
  border-radius: 50%;
  background-color: $violet;
  position: absolute;
  animation: circleBackAnimation 1s 0s forwards;
}

.bottomRightBgCircle {
  bottom: 0;
  right: 0;
  z-index: 1;
  width: 65%;
  height: 38%;
  position: absolute;
  animation: circleAnimation 1s 0s forwards, zoom-in-zoom-out 3s 1s infinite;
  transform-origin: bottom right;
}
.bottomRightBgCircleOff {
  bottom: 0;
  right: 0;
  z-index: 1;
  width: 65%;
  height: 38%;
  position: absolute;
  animation: circleBackAnimation 1s 0s forwards;
  transform-origin: bottom right;
}

.topLeftDesktopBgCircle {
  display: none;
}
.BottomLeftDesktopBgCircle {
  display: none;
}
.BottomRightDesktopBgCircle {
  display: none;
}

.topLeftDesktopVisitedBgCircleOff {
  display: none;
}
.BottomLeftDesktopVisitedBgCircleOff {
  display: none;
}
.BottomRightDesktopBgCircleOff {
  display: none;
}

.secondPageWrapper {
  z-index: 5;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
}

.bounce_in_top {
  margin-top: -100px;
  -webkit-animation: bounce-in-top 2s both;
  animation: bounce-in-top 2s both;
  transition-timing-function: linear;
}

.bigBounce_in_top {
  display: none;
}

.prior {
  z-index: 5;
}

.join_text {
  padding-top: 50px;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  animation: onOpacityAnimation 3s 0s 1;
  transition-timing-function: linear;
}

.contact_block {
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  animation: onOpacityAnimation 3s 0s 1;
  transition-timing-function: linear;
}

// .contact_button {
//   margin-top: 16px;
//   padding: 14px 32px;
//   font-size: 16px;
//   font-weight: bold;
//   color: white;
//   background-color: black;
//   border: none;
//   height: 50px;
//   border-radius: 100px;
//   &:hover {
//     opacity: 0.5;
//     cursor: pointer;
//   }
// }

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: opacityAnimation 3s 0s 1;
}

.social_media {
  display: flex;
  gap: 16px;
}

.license_text {
  color: #383635;
  font-size: 12px;
  padding-top: 16px;
}

.top_block {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (min-width: 375px) {
  .appWrapper {
    width: 100%;
    min-height: 100vh;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
  }

  .topLeftBgCircle {
    top: 0;
    left: -55px;
    z-index: 1;
    width: 59%;
    height: 40%;
    position: absolute;
  }

  .topLeftBgCircleVisited {
    top: 0;
    left: -55px;
    z-index: 1;
    width: 59%;
    height: 40%;
    position: absolute;
  }

  .bottomLeftBgCircle {
    bottom: 0;
    left: -17px;
    z-index: 1;
    width: 40%;
    height: 33%;
    position: absolute;
  }

  .topRightBgCircle {
    top: -320px;
    right: -389px;
    z-index: 1;
    width: 600px;
    height: 600px;
    border-radius: 50%;
    background-color: $violet;
    position: absolute;
  }

  .topRightBgCircleVisited {
    top: -320px;
    right: -389px;
    z-index: 1;
    width: 600px;
    height: 600px;
    border-radius: 50%;
    background-color: $violet;
    position: absolute;
  }

  .bottomRightBgCircle {
    bottom: 0;
    right: 0;
    z-index: 1;
    width: 65%;
    height: 38%;
    position: absolute;
  }
  .secondPageWrapper {
    z-index: 5;
    display: flex;
    height: calc(100vh - 110px);
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
  }
  .bounce_in_top {
    margin-top: -100px;
  }
  .contact_block {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .contact_button {
    margin-top: 16px;
    padding: 14px 32px;
    font-size: 16px;
    font-weight: bold;
    color: white;
    background-color: black;
    border: none;
    height: 50px;
    border-radius: 100px;
  }
}

@media (min-width: 600px) {
  .appWrapper {
    width: 100%;
    min-height: 100vh;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
  }

  .topLeftBgCircle {
    top: 0;
    left: 0;
    z-index: 1;
    width: 43%;
    height: 44%;
    position: absolute;
  }

  .topLeftBgCircleVisited {
    top: 0;
    left: 0;
    z-index: 1;
    width: 43%;
    height: 44%;
    position: absolute;
  }

  .bottomLeftBgCircle {
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 33%;
    height: 50%;
    position: absolute;
  }

  .topRightBgCircle {
    top: -260px;
    right: -380px;
    z-index: 1;
    width: 600px;
    height: 600px;
    border-radius: 50%;
    background-color: $violet;
    position: absolute;
  }

  .topRightBgCircleVisited {
    top: -260px;
    right: -380px;
    z-index: 1;
    width: 600px;
    height: 600px;
    border-radius: 50%;
    background-color: $violet;
    position: absolute;
  }

  .bottomRightBgCircle {
    bottom: 0;
    right: 0;
    z-index: 1;
    width: 50%;
    height: 45%;
    position: absolute;
  }
  .secondPageWrapper {
    z-index: 5;
    display: flex;
    height: calc(100vh - 80px);
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
  }
  .contact_block {
    display: block;
    padding-top: 24px;
  }
  .bounce_in_top {
    display: none;
  }

  .bigBounce_in_top {
    display: block;
    margin-top: -60px;
    -webkit-animation: bounce-in-top 1.1s both;
    animation: bounce-in-top 1.1s both;
  }

  .join_text br {
    display: none;
  }
  .contact_button {
    padding: 14px 32px;
    font-size: 16px;
    font-weight: bold;
    color: white;
    background-color: black;
    border: none;
    height: 50px;
    border-radius: 100px;
  }
  .footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

@media (min-width: 800px) {
  .topLeftBgCircle {
    top: 0;
    left: 0;
    z-index: 1;
    width: 40%;
    height: 45%;
    position: absolute;
  }

  .topLeftBgCircleVisited {
    top: 0;
    left: 0;
    z-index: 1;
    width: 40%;
    height: 45%;
    position: absolute;
  }

  .topLeftBgCircleVisitedOff {
    top: 0;
    left: 0;
    z-index: 1;
    width: 40%;
    height: 45%;
    position: absolute;
  }

  .bottomLeftBgCircle {
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 35%;
    height: 60%;
    position: absolute;
  }

  .bottomLeftVisitedBgCircle {
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 35%;
    height: 60%;

    position: absolute;
  }

  .bottomLeftVisitedBgCircleOff {
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 35%;
    height: 60%;

    position: absolute;
  }

  .topRightBgCircle {
    top: -665px;
    right: -772px;
    z-index: 1;
    width: 1110px;
    height: 1110px;
    border-radius: 50%;
    background-color: $violet;
    position: absolute;
  }

  .topRightBgCircleVisited {
    top: -665px;
    right: -772px;
    z-index: 1;
    width: 1110px;
    height: 1110px;
    border-radius: 50%;
    background-color: $violet;
    position: absolute;
  }

  .topRightBgCircleVisitedOff {
    top: -665px;
    right: -772px;
    z-index: 1;
    width: 1110px;
    height: 1110px;
    border-radius: 50%;
    background-color: $violet;
    position: absolute;
  }

  .bottomRightBgCircle {
    bottom: 0;
    right: 0;
    z-index: 1;
    width: 42%;
    height: 50%;
    position: absolute;
  }

  .bottomRightBgCircleOff {
    bottom: 0;
    right: 0;
    z-index: 1;
    width: 42%;
    height: 50%;
    position: absolute;
  }
}
@media (min-width: 1170px) {
  .topLeftBgCircle {
    top: 0;
    left: 0;
    z-index: 1;
    width: 30%;
    height: 30%;
    position: absolute;
  }

  .topLeftBgCircleVisited {
    top: 0;
    left: 0;
    z-index: 1;
    width: 30%;
    height: 30%;
    position: absolute;
  }
  .bottomLeftBgCircle {
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 35%;
    height: 74%;
    position: absolute;
  }
  .bottomLeftVisitedBgCircle {
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 35%;
    height: 74%;
    position: absolute;
  }
  .bottomLeftVisitedBgCircleOff {
    display: none;
  }
  .bottomRightBgCircle {
    bottom: 0;
    right: 0;
    z-index: 1;
    width: 33%;
    height: 48%;
    position: absolute;
  }
}
@media (min-width: 1366px) {
  .appWrapper {
    width: 100%;
    min-height: 100vh;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
  }
  .topLeftBgCircle {
    top: 0;
    left: 0;
    z-index: 1;
    width: 25%;
    height: 30%;
    position: absolute;
    display: none;
  }

  .topLeftBgCircleVisited {
    top: 0;
    left: 0;
    z-index: 1;
    width: 25%;
    height: 30%;
    position: absolute;
    display: none;
  }
  .topLeftBgCircleVisitedOff {
    top: 0;
    left: 0;
    z-index: 1;
    width: 25%;
    height: 30%;
    position: absolute;
    display: none;
  }
  .bottomLeftBgCircle {
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 35%;
    height: 77%;
    position: absolute;
    display: none;
  }

  .bottomLeftVisitedBgCircle {
    display: none;
  }

  .topRightBgCircle {
    top: -730px;
    right: -642px;
    z-index: 1;
    width: 1110px;
    height: 1110px;
    border-radius: 50%;
    background-color: $violet;
    position: absolute;
  }

  .topRightBgCircleVisited {
    top: -730px;
    right: -642px;
    z-index: 1;
    width: 1110px;
    height: 1110px;
    border-radius: 50%;
    background-color: $violet;
    position: absolute;
    animation: circleAnimation 1s 0s forwards, zoom-in-zoom-out 3s 1s infinite;
  }
  .topRightBgCircleVisitedOff {
    top: -730px;
    right: -642px;
    z-index: 1;
    width: 1110px;
    height: 1110px;
    border-radius: 50%;
    background-color: $violet;
    position: absolute;
    animation: circleBackAnimation 1s 0s forwards;
  }

  .bottomRightBgCircle {
    bottom: 0;
    right: 0;
    z-index: 1;
    width: 33%;
    height: 55%;
    position: absolute;
    display: none;
  }
  .bottomRightBgCircleOff {
    bottom: 0;
    right: 0;
    z-index: 1;
    width: 33%;
    height: 55%;
    position: absolute;
    display: none;
  }
  .topLeftDesktopBgCircle {
    top: 0;
    left: 0;
    z-index: 1;
    width: 30%;
    height: 25%;
    position: absolute;
    display: block;
    animation: zoom-in-zoom-out 3s 0s infinite,
      offOpacityAnimation 0.1s 2.1s forwards;
  }

  .topLeftDesktopVisitedBgCircle {
    top: 0;
    left: 0;
    z-index: 1;
    width: 30%;
    height: 25%;
    position: absolute;
    display: block;
    animation: circleAnimation 1s 0s forwards, zoom-in-zoom-out 3s 1s infinite;
    transform-origin: top left;
  }
  .topLeftDesktopVisitedBgCircleOff {
    top: 0;
    left: 0;
    z-index: 1;
    width: 30%;
    height: 25%;
    position: absolute;
    display: block;
    animation: circleBackAnimation 1s 0s forwards;
    transform-origin: top left;
  }
  .BottomLeftDesktopBgCircle {
    display: block;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 35%;
    height: 75%;
    position: absolute;
    animation: zoom-in-zoom-out 3s 0s infinite, offOpacityAnimation 1s 2s 1;
  }

  .BottomLeftDesktopVisitedBgCircle {
    display: block;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 35%;
    height: 75%;
    position: absolute;
    animation: circleAnimation 1s 0s forwards, zoom-in-zoom-out 3s 1s infinite;
    transform-origin: bottom left;
  }
  .BottomLeftDesktopVisitedBgCircleOff {
    display: block;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 35%;
    height: 75%;
    position: absolute;
    animation: circleBackAnimation 1s 0s forwards;
    transform-origin: bottom left;
  }
  .BottomRightDesktopBgCircle {
    display: block;
    bottom: 0;
    right: 0;
    z-index: 1;
    width: 33%;
    height: 51.5%;
    position: absolute;
    animation: circleAnimation 1s 0s forwards, zoom-in-zoom-out 3s 1s infinite;
    transform-origin: bottom right;
  }
  .BottomRightDesktopBgCircleOff {
    display: block;
    bottom: 0;
    right: 0;
    z-index: 1;
    width: 33%;
    height: 51.5%;
    position: absolute;
    animation: circleBackAnimation 1s 0s forwards;
    transform-origin: bottom right;
  }
  .secondPageWrapper {
    z-index: 5;
    display: flex;
    height: calc(100vh - 80px);
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
  }

  .bounce_in_top {
    margin-top: -60px;
    -webkit-animation: bounce-in-top 1.1s both;
    animation: bounce-in-top 1.1s both;
  }

  // .prior {
  //   z-index: 5;
  // }

  // .join_text {
  //   padding-top: 50px;
  //   font-size: 18px;
  //   line-height: 24px;
  // }

  // .contact_block {
  //   padding-top: 24px;
  // }

  // .contact_button {
  //   padding: 14px 32px;
  //   font-size: 16px;
  //   font-weight: bold;
  //   color: white;
  //   background-color: black;
  //   border: none;
  //   height: 50px;
  //   border-radius: 100px;
  // }

  .footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  // .social_media {
  //   display: flex;
  //   gap: 16px;
  // }

  // .license_text {
  //   color: #383635;
  //   font-size: 12px;
  //   padding-top: 16px;
  // }

  // .top_block {
  //   display: flex;
  //   flex-direction: column;
  //   align-items: center;
  // }
}

@media (min-width: 1920px) {
  .topLeftDesktopBgCircle {
    top: 0;
    left: 0;
    z-index: 1;
    width: 30%;
    height: 30%;
    position: absolute;
  }

  .topLeftDesktopVisitedBgCircle {
    top: 0;
    left: 0;
    z-index: 1;
    width: 30%;
    height: 30%;
    position: absolute;
  }
  .topLeftDesktopVisitedBgCircleOff {
    top: 0;
    left: 0;
    z-index: 1;
    width: 30%;
    height: 30%;
    position: absolute;
  }
  .topLeftBgFullCircle {
    top: -645px;
    left: -360px;
    z-index: 1;
    width: 900px;
    height: 900px;
    border-radius: 50%;
    background-color: $violet;
    position: absolute;
    animation: topLeftFullCircleAnimation 1.5s 2s forwards;
    transition-timing-function: ease-out;
  }

  .BottomLeftDesktopBgCircle {
    left: 0;
    bottom: 0;
    z-index: 1;
    width: 30%;
    height: 70%;
    position: absolute;
  }

  .topRightBgCircle {
    top: -1030px;
    right: -780px;
    z-index: 1;
    width: 1500px;
    height: 1500px;
    border-radius: 50%;
    background-color: $violet;
    position: absolute;
  }

  .topRightBgCircleVisited {
    top: -1030px;
    right: -780px;
    z-index: 1;
    width: 1500px;
    height: 1500px;
    border-radius: 50%;
    background-color: $violet;
    position: absolute;
  }
  .topRightBgCircleVisitedOff {
    top: -1030px;
    right: -780px;
    z-index: 1;
    width: 1500px;
    height: 1500px;
    border-radius: 50%;
    background-color: $violet;
    position: absolute;
  }
  .BottomRightDesktopBgCircle {
    right: 0;
    bottom: 0;
    z-index: 1;
    width: 32%;
    height: 50%;
    position: absolute;
  }
  .BottomRightDesktopBgCircleOff {
    right: 0;
    bottom: 0;
    z-index: 1;
    width: 32%;
    height: 50%;
    position: absolute;
  }
}
.titlePage {
  animation: onOpacityAnimation 2s 0s forwards;
}
.isCongratulations {
  display: none;
}

@media (max-height: 600px) {
  .topLeftBgCircle {
    top: -40px;
    left: -25px;
    z-index: 1;
    width: 55%;
    height: 36%;
    position: absolute;
    animation: zoom-in-zoom-out 3s 0s infinite,
      offOpacityAnimation 0.1s 2.1s forwards;
  }
  .topLeftBgCircleVisited {
    top: -40px;
    left: -25px;
    z-index: 1;
    width: 55%;
    height: 36%;
    position: absolute;
    animation: circleAnimation 1s 0s forwards, zoom-in-zoom-out 3s 1s infinite;
    transform-origin: top left;
  }
  .topLeftBgCircleVisitedOff {
    top: -40px;
    left: -25px;
    z-index: 1;
    width: 55%;
    height: 36%;
    position: absolute;
    animation: circleBackAnimation 1s 0s forwards;
    transform-origin: top left;
  }
  .topLeftDesktopVisitedBgCircle {
    display: none;
  }
  .topLeftBgFullCircle {
    top: -610px;
    left: -720px;
    z-index: 0;
    width: 700px;
    height: 700px;
    border-radius: 50%;
    background-color: $violet;
    position: absolute;
    animation: topLeftFullCircleAnimation 1.5s 2s forwards;
    transition-timing-function: ease-out;
  }

  .bottomLeftBgCircle {
    left: -16px;
    bottom: 0;
    z-index: 1;
    width: 36%;
    height: 29%;
    position: absolute;
    animation: zoom-in-zoom-out 3s 0s infinite,
      offOpacityAnimation 1s 2.2s forwards;
  }
  .bottomLeftVisitedBgCircle {
    left: -16px;
    bottom: 0;
    z-index: 1;
    width: 36%;
    height: 29%;
    position: absolute;
    animation: circleAnimation 1s 0s forwards, zoom-in-zoom-out 3s 1s infinite;
    transform-origin: bottom left;
  }
  .bottomLeftVisitedBgCircleOff {
    left: -16px;
    bottom: 0;
    z-index: 1;
    width: 36%;
    height: 29%;
    position: absolute;
    animation: circleBackAnimation 1s 0s forwards;
    transform-origin: bottom left;
  }

  .topRightBgCircle {
    top: -320px;
    right: -429px;
    z-index: 1;
    width: 550px;
    height: 550px;
    border-radius: 50%;
    background-color: $violet;
    position: absolute;
    animation: topRightCircleAnimation 3s 2s forwards, zoom-out-zoom-in 2s 1s 1;
    transition-timing-function: linear;
  }

  .topRightBgCircleVisited {
    top: -320px;
    right: -429px;
    z-index: 1;
    width: 550px;
    height: 550px;
    border-radius: 50%;
    background-color: $violet;
    position: absolute;
    animation: circleAnimation 1s 0s forwards, zoom-in-zoom-out 3s 1s infinite;
  }

  .topRightBgCircleVisitedOff {
    top: -320px;
    right: -429px;
    z-index: 1;
    width: 550px;
    height: 550px;
    border-radius: 50%;
    background-color: $violet;
    position: absolute;
    animation: circleBackAnimation 1s 0s forwards;
  }

  .bottomRightBgCircle {
    bottom: 0;
    right: 0;
    z-index: 1;
    width: 61%;
    height: 34%;
    position: absolute;
    animation: circleAnimation 1s 0s forwards, zoom-in-zoom-out 3s 1s infinite;
    transform-origin: bottom right;
  }
  .bottomRightBgCircleOff {
    bottom: 0;
    right: 0;
    z-index: 1;
    width: 61%;
    height: 34%;
    position: absolute;
    animation: circleBackAnimation 1s 0s forwards;
    transform-origin: bottom right;
  }
}

.mobileContentWrapper {
  animation: onOpacityAnimation 1s 1s forwards;
}

.mobileContentWrapperOffAnimation {
  animation: onOpacityAnimation 1s 0s forwards;
}

.mobileContentWrapperHide {
  animation: offOpacityAnimation 1s 0s forwards;
}

.mobailHeaderBackground {
  width: 100%;
  height: 60px;
  position: fixed;
  z-index: 10;
  background-color: rgba(244, 238, 226, 1);
  display: flex;
  justify-content: flex-end;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-right: 20px;
  box-shadow: 0 4px 5px 0px rgba(0, 0, 0, 0.25);
}

.mobailHeaderOffOpacityBackground {
  width: 100%;
  height: 60px;
  position: fixed;
  z-index: 10;
  display: flex;
  justify-content: flex-end;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-right: 20px;
}
.mobailHeaderContactUsButton {
  padding: 0px 32px;
  font-family: "Urbanist";
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 2%;
  color: white;
  border-radius: 100px;
  background-color: black;
  border: none;
}
