@import "../../../App.module.scss";

@keyframes friendsPageAnimation {
  0% {
    opacity: 0;
    transform: scale(1.5);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes friendsPageAnimationOff {
  0% {
    top: -850px;
    opacity: 1;
    transform: scale(1);
  }
  100% {
    top: 0px;
    transform: scale(1.5);
  }
}
@keyframes friendsPageAnimationFromContact {
  0% {
    top: -1550px;
    opacity: 1;
    transform: scale(1.5);
  }
  100% {
    top: -850px;
    transform: scale(1);
  }
}
@keyframes friendsPageAnimationToContact {
  0% {
    top: -850px;
    left: -20px;
    opacity: 1;
    transform: scale(1);
  }
  100% {
    top: -2550px;
    opacity: 1;
    left: 0px;
    transform: scale(1.5);
  }
}

.friendsPage {
  position: relative;
  padding: 296px 0px 0px 0px;
  height: 100vh;
  background-color: #7d77b7;
}

.friendsPageHide {
  position: relative;
  padding: 296px 0px 155px 0px;
  background-color: #7d77b7;
  height: 100vh;
  position: relative;
}

.canvasWrapper {
  position: relative;
  top: -950px;
  animation: friendsPageAnimation 0.5s 0s forwards;
}

.canvasWrapperHide {
  position: relative;
  top: -950px;
  animation: friendsPageAnimationOff 0.5s 0s forwards;
}
.canvasWrapperHideFromContact {
  position: relative;
  top: -950px;
  animation: friendsPageAnimationFromContact 0.5s 0s forwards;
}

.canvasWrapperToContact {
  position: relative;
  top: -950px;
  animation: friendsPageAnimationToContact 0.5s 0s forwards;
}
.footerCanvas {
  width: 100%;
  height: 100px;
  position: absolute;
  top: 200px;
  overflow: visible;
  z-index: 2;
  margin-bottom: 30px;
  animation: onOpacityAnimation 2s 0s forwards, canvasAnimation 1s 0s forwards;
}
.footerCanvas2 {
  width: 100%;
  height: 100px;
  position: absolute;
  top: 250px;
  overflow: visible;
  z-index: 2;
  margin-bottom: 30px;
  animation: onOpacityAnimation 1s 0s forwards, canvasAnimation2 1s 0s forwards;
}
.footerCanvas3 {
  width: 100%;
  height: 100px;
  position: absolute;
  top: 300px;
  overflow: visible;
  z-index: 2;
  margin-bottom: 30px;
  animation: onOpacityAnimation 1s 0s forwards, canvasAnimation3 1s 0s forwards;
}
.footerCanvas4 {
  width: 100%;
  height: 100px;
  position: absolute;
  top: 350px;
  overflow: visible;
  z-index: 2;
  margin-bottom: 30px;
  animation: onOpacityAnimation 1s 0s forwards, canvasAnimation4 1s 0s forwards;
}
.footerCanvas5 {
  width: 100%;
  height: 100px;
  position: absolute;
  top: 400px;
  overflow: visible;
  z-index: 2;
  margin-bottom: 30px;
  animation: onOpacityAnimation 1s 0s forwards, canvasAnimation5 1s 0s forwards;
}
.footerCanvas6 {
  width: 100%;
  height: 100px;
  position: absolute;
  top: 450px;
  overflow: visible;
  z-index: 2;
  margin-bottom: 30px;
  animation: onOpacityAnimation 1s 0s forwards, canvasAnimation6 1s 0s forwards;
}
.footerCanvas7 {
  width: 100%;
  height: 100px;
  position: absolute;
  top: 500px;
  overflow: visible;
  z-index: 2;
  margin-bottom: 30px;
  animation: onOpacityAnimation 1s 0s forwards, canvasAnimation7 1s 0s forwards;
}
.footerCanvas8 {
  width: 100%;
  height: 100px;
  position: absolute;
  top: 500px;
  overflow: visible;
  z-index: 2;
  margin-bottom: 30px;
  animation: canvasAnimation8 1s 0s forwards;
}
.footerCanvas9 {
  width: 100%;
  height: 100px;
  position: absolute;
  top: 550px;
  overflow: visible;
  z-index: 2;
  margin-bottom: 30px;
  animation: canvasAnimation9 1s 0s forwards;
}
.footerCanvas10 {
  width: 100%;
  height: 100px;
  position: absolute;
  top: 600px;
  overflow: visible;
  z-index: 2;
  margin-bottom: 30px;
  animation: canvasAnimation10 1s 0s forwards;
}
.footerCanvas11 {
  width: 100%;
  height: 100px;
  position: absolute;
  top: 650px;
  overflow: visible;
  z-index: 2;
  margin-bottom: 30px;
  animation: canvasAnimation11 1s 0s forwards;
}
.footerCanvas12 {
  width: 100%;
  height: 100px;
  position: absolute;
  top: 700px;
  overflow: visible;
  z-index: 2;
  margin-bottom: 30px;
  animation: canvasAnimation12 1s 0s forwards;
}

@keyframes onOpacityAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.friendsPageWrapper {
  height: 540px;
  position: relative;
  margin-right: auto;
  margin-left: auto;
  animation: onOpacityAnimation 0.5s 0s forwards;
}

.friendsPageWrapperHide {
  height: 540px;
  position: relative;
  margin-right: auto;
  margin-left: auto;
  animation: offOpacityAnimation 0.5s 0s forwards;
}
.friendsPageTextWrapper {
  display: flex;
  justify-content: center;
}
.friendsPageTitle {
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
  letter-spacing: 2%;
  text-transform: uppercase;
  position: absolute;
  top: -8%;
  text-align: center;
  z-index: 4;
}

.friendsPageSubtitle {
  margin-top: 8px;
  text-align: center;
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 800;
  font-size: 40px;
  line-height: 48px;
  color: #ffffff;
  letter-spacing: -2%;
  text-transform: uppercase;
  position: absolute;
  top: 8.5%;
  z-index: 4;
}

.alexIMG {
  width: 137px;
  height: 213px;
  position: absolute;
  z-index: 3;
}
.denysIMG {
  width: 132px;
  height: 161px;
  position: absolute;
  z-index: 3;
  top: 20%;
  left: 50%;
}
.dianaIMG {
  height: 155px;
  position: absolute;
  top: -30%;
  left: 65%;
  z-index: 3;
}
.blot1 {
  // background-image: url(../../../assets/blot1.png);
  // background-size: contain;
  position: absolute;
  // background-repeat: no-repeat;
  z-index: 3;
  width: 75px;
  height: 73px;
  left: 30%;
  top: 150px;
}
.blot2 {
  // background-image: url(../../../assets/blot2.png);
  // background-size: contain;
  // background-position: center;
  // background-repeat: no-repeat;
  position: absolute;
  z-index: 3;
  width: 107px;
  height: 91px;
  left: 12%;
  top: -5%;
}
.blot3 {
  // background-image: url(../../../assets/blot3.png);
  // background-size: cover;
  position: absolute;
  z-index: 3;
  width: 79px;
  height: 67px;
  left: 75%;
  top: 20%;
}
.blot4 {
  // background-image: url(../../../assets/blot4.png);
  // background-size: cover;
  position: absolute;
  z-index: 3;
  width: 79px;
  height: 67px;
  left: 748px;
  top: 176px;
}
.blot5 {
  // background-image: url(../../../assets/blot5.png);
  // background-size: cover;
  position: absolute;
  z-index: 3;
  width: 67px;
  height: 68px;
  left: 1026px;
  top: -5px;
}
.blot6 {
  // background-image: url(../../../assets/blot6.png);
  // background-size: cover;
  position: absolute;
  z-index: 3;
  width: 56px;
  height: 57px;
  left: 1182px;
  top: 263px;
}

@media (max-width: 600px) {
  .friendsPageTitle {
    font-family: "Urbanist";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #ffffff;
    letter-spacing: 2%;
    text-transform: uppercase;
    position: absolute;
    top: -15%;
    text-align: center;
    z-index: 4;
  }

  .friendsPageSubtitle {
    margin-top: 8px;
    text-align: center;
    font-family: "Urbanist";
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 48px;
    color: #ffffff;
    letter-spacing: -2%;
    text-transform: uppercase;
    position: absolute;
    top: 9%;
    z-index: 4;
  }
  .alexIMG {
    width: 137px;
    height: 213px;
    position: absolute;
    z-index: 3;
    top: -5%;
    left: -3%;
  }
  .denysIMG {
    width: 132px;
    height: 161px;
    position: absolute;
    z-index: 3;
    top: 10%;
    left: 50%;
  }
  .dianaIMG {
    height: 155px;
    position: absolute;
    top: -38%;
    left: 65%;
    z-index: 3;
  }
  .blot1 {
    // background-image: url(../../../assets/blot1.png);
    // background-size: contain;
    // position: absolute;
    // background-repeat: no-repeat;
    z-index: 3;
    width: 75px;
    height: 73px;
    left: 30%;
    top: 150px;
  }
  .blot2 {
    // background-image: url(../../../assets/blot2.png);
    // background-size: contain;
    // background-position: center;
    // background-repeat: no-repeat;
    position: absolute;
    z-index: 3;
    width: 107px;
    height: 91px;
    left: 12%;
    top: -5%;
  }
  .blot3 {
    // background-image: url(../../../assets/blot3.png);
    // background-size: cover;
    position: absolute;
    z-index: 3;
    width: 79px;
    height: 67px;
    left: 75%;
    top: 110px;
  }
  .blot4 {
    // background-image: url(../../../assets/blot4.png);
    // background-size: cover;
    position: absolute;
    z-index: 3;
    width: 79px;
    height: 67px;
    left: 748px;
    top: 176px;
  }
  .blot5 {
    // background-image: url(../../../assets/blot5.png);
    // background-size: cover;
    position: absolute;
    z-index: 3;
    width: 67px;
    height: 68px;
    left: 1026px;
    top: -5px;
  }
  .blot6 {
    // background-image: url(../../../assets/blot6.png);
    // background-size: cover;
    position: absolute;
    z-index: 3;
    width: 56px;
    height: 57px;
    left: 1182px;
    top: 263px;
  }
}
@media (min-width: 800px) {
  .alexIMG {
    width: auto;
    height: auto;
    position: absolute;
    z-index: 3;
  }
  .denysIMG {
    width: auto;
    height: auto;
    position: absolute;
    z-index: 3;
    top: 20%;
    left: 50%;
  }
  .dianaIMG {
    width: auto;
    height: auto;
    position: absolute;

    z-index: 3;
    top: -50%;
    left: 65%;
  }
}
@media (min-width: 982px) {
  .friendsPage {
    position: relative;
    padding: 296px 0px 155px 0px;
    height: 100vh;
    background-color: #7d77b7;
  }

  .friendsPageHide {
    position: relative;
    padding: 296px 0px 155px 0px;
    background-color: #7d77b7;
    height: 100vh;
    position: relative;
  }

  .canvasWrapper {
    position: relative;
    top: -850px;
    animation: friendsPageAnimation 0.5s 0s forwards;
  }

  .canvasWrapperHide {
    position: relative;
    top: -850px;
    animation: friendsPageAnimationOff 0.5s 0s forwards;
  }
  .canvasWrapperHideFromContact {
    position: relative;
    top: -850px;
    animation: friendsPageAnimationFromContact 0.5s 0s forwards;
  }

  .canvasWrapperToContact {
    position: relative;
    top: -850px;
    animation: friendsPageAnimationToContact 0.5s 0s forwards;
  }
  .footerCanvas {
    width: 100%;
    height: 100px;
    position: absolute;
    top: 200px;
    overflow: visible;
    z-index: 2;
    margin-bottom: 30px;
    animation: onOpacityAnimation 2s 0s forwards, canvasAnimation 1s 0s forwards;
  }
  .footerCanvas2 {
    width: 100%;
    height: 100px;
    position: absolute;
    top: 260px;
    overflow: visible;
    z-index: 2;
    margin-bottom: 30px;
    animation: onOpacityAnimation 1s 0s forwards,
      canvasAnimation2 1s 0s forwards;
  }
  .footerCanvas3 {
    width: 100%;
    height: 100px;
    position: absolute;
    top: 320px;
    overflow: visible;
    z-index: 2;
    margin-bottom: 30px;
    animation: onOpacityAnimation 1s 0s forwards,
      canvasAnimation3 1s 0s forwards;
  }
  .footerCanvas4 {
    width: 100%;
    height: 100px;
    position: absolute;
    top: 380px;
    overflow: visible;
    z-index: 2;
    margin-bottom: 30px;
    animation: onOpacityAnimation 1s 0s forwards,
      canvasAnimation4 1s 0s forwards;
  }
  .footerCanvas5 {
    width: 100%;
    height: 100px;
    position: absolute;
    top: 440px;
    overflow: visible;
    z-index: 2;
    margin-bottom: 30px;
    animation: onOpacityAnimation 1s 0s forwards,
      canvasAnimation5 1s 0s forwards;
  }
  .footerCanvas6 {
    width: 100%;
    height: 100px;
    position: absolute;
    top: 500px;
    overflow: visible;
    z-index: 2;
    margin-bottom: 30px;
    animation: onOpacityAnimation 1s 0s forwards,
      canvasAnimation6 1s 0s forwards;
  }
  .footerCanvas7 {
    width: 100%;
    height: 100px;
    position: absolute;
    top: 550px;
    overflow: visible;
    z-index: 2;
    margin-bottom: 30px;
    animation: onOpacityAnimation 1s 0s forwards,
      canvasAnimation7 1s 0s forwards;
  }
  .footerCanvas8 {
    width: 100%;
    height: 100px;
    position: absolute;
    top: 500px;
    overflow: visible;
    z-index: 2;
    margin-bottom: 30px;
    animation: canvasAnimation8 1s 0s forwards;
  }
  .footerCanvas9 {
    width: 100%;
    height: 100px;
    position: absolute;
    top: 550px;
    overflow: visible;
    z-index: 2;
    margin-bottom: 30px;
    animation: canvasAnimation9 1s 0s forwards;
  }
  .footerCanvas10 {
    width: 100%;
    height: 100px;
    position: absolute;
    top: 600px;
    overflow: visible;
    z-index: 2;
    margin-bottom: 30px;
    animation: canvasAnimation10 1s 0s forwards;
  }
  .footerCanvas11 {
    width: 100%;
    height: 100px;
    position: absolute;
    top: 650px;
    overflow: visible;
    z-index: 2;
    margin-bottom: 30px;
    animation: canvasAnimation11 1s 0s forwards;
  }
  .footerCanvas12 {
    width: 100%;
    height: 100px;
    position: absolute;
    top: 700px;
    overflow: visible;
    z-index: 2;
    margin-bottom: 30px;
    animation: canvasAnimation12 1s 0s forwards;
  }

  @keyframes onOpacityAnimation {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .friendsPageWrapper {
    width: 1366px;
    height: 540px;
    position: relative;
    margin-right: auto;
    margin-left: auto;
    animation: onOpacityAnimation 0.5s 0s forwards;
  }

  .friendsPageWrapperHide {
    width: 1366px;
    height: 540px;
    position: relative;
    margin-right: auto;
    margin-left: auto;
    animation: offOpacityAnimation 0.5s 0s forwards;
  }

  .friendsPageTitle {
    text-align: center;
    font-family: "Urbanist";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #ffffff;
    letter-spacing: -2%;
    text-transform: uppercase;
    position: absolute;
    left: 648px;
    top: 65px;
    z-index: 4;
  }

  .friendsPageSubtitle {
    text-align: center;
    font-family: "Urbanist";
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 48px;
    color: #ffffff;
    letter-spacing: -2%;
    text-transform: uppercase;
    position: absolute;
    left: 355px;
    top: 55px;
    z-index: 4;
  }

  .alexIMG {
    width: auto;
    height: auto;
    position: absolute;
    z-index: 3;
    top: 18px;
    left: 153px;
  }
  .denysIMG {
    width: auto;
    height: auto;
    position: absolute;
    z-index: 3;
    top: 186px;
    left: 546px;
  }
  .dianaIMG {
    width: auto;
    height: auto;
    position: absolute;
    left: 65%;
    z-index: 3;
    top: 105px;
    left: 939px;
  }
  .blot1 {
    // background-image: url(../../../assets/blot1.png);
    // background-size: contain;
    position: absolute;
    z-index: 3;
    width: 55px;
    height: 68px;
    left: 108px;
    top: 60px;
  }
  .blot2 {
    // background-image: url(../../../assets/blot2.png);
    // background-size: contain;
    // background-position: center;
    // background-repeat: no-repeat;
    position: absolute;
    z-index: 3;
    width: 113px;
    height: 116px;
    left: 395px;
    top: 150px;
  }
  .blot3 {
    // background-image: url(../../../assets/blot3.png);
    // background-size: cover;
    position: absolute;
    z-index: 3;
    width: 102px;
    height: 89px;
    left: 565px;
    top: -58px;
  }
  .blot4 {
    // background-image: url(../../../assets/blot4.png);
    // background-size: cover;
    position: absolute;
    z-index: 3;
    width: 113px;
    height: 96px;
    left: 748px;
    top: 130px;
  }
  .blot5 {
    // background-image: url(../../../assets/blot5.png);
    // background-size: cover;
    position: absolute;
    z-index: 3;
    width: 67px;
    height: 68px;
    left: 1026px;
    top: -35px;
  }
  .blot6 {
    // background-image: url(../../../assets/blot6.png);
    // background-size: cover;
    position: absolute;
    z-index: 3;
    width: 56px;
    height: 57px;
    left: 1150px;
    top: 180px;
  }
}

@media (min-width: 1200px) {
  .friendsPageSubtitle {
    text-align: center;
    font-family: "Urbanist";
    font-style: normal;
    font-weight: 800;
    font-size: 60px;
    line-height: 72px;
    color: #ffffff;
    letter-spacing: -2%;
    text-transform: uppercase;
    position: absolute;
    left: 300px;
    top: 48px;
    z-index: 4;
  }
}

@media (min-width: 1366px) {
  .friendsPage {
    position: relative;
    padding: 296px 0px 155px 0px;
    height: 100vh;
    background-color: #7d77b7;
  }

  .friendsPageHide {
    position: relative;
    padding: 296px 0px 155px 0px;
    background-color: #7d77b7;
    height: 100vh;
    position: relative;
  }

  .canvasWrapper {
    position: relative;
    top: -850px;
    animation: friendsPageAnimation 0.5s 0s forwards;
  }

  .canvasWrapperHide {
    position: relative;
    top: -850px;
    animation: friendsPageAnimationOff 0.5s 0s forwards;
  }
  .canvasWrapperHideFromContact {
    position: relative;
    top: -850px;
    animation: friendsPageAnimationFromContact 0.5s 0s forwards;
  }

  .canvasWrapperToContact {
    position: relative;
    top: -850px;
    animation: friendsPageAnimationToContact 0.5s 0s forwards;
  }
  .footerCanvas {
    width: 100%;
    height: 100px;
    position: absolute;
    top: 200px;
    overflow: visible;
    z-index: 2;
    margin-bottom: 30px;
    animation: onOpacityAnimation 2s 0s forwards, canvasAnimation 1s 0s forwards;
  }
  .footerCanvas2 {
    width: 100%;
    height: 100px;
    position: absolute;
    top: 260px;
    overflow: visible;
    z-index: 2;
    margin-bottom: 30px;
    animation: onOpacityAnimation 1s 0s forwards,
      canvasAnimation2 1s 0s forwards;
  }
  .footerCanvas3 {
    width: 100%;
    height: 100px;
    position: absolute;
    top: 320px;
    overflow: visible;
    z-index: 2;
    margin-bottom: 30px;
    animation: onOpacityAnimation 1s 0s forwards,
      canvasAnimation3 1s 0s forwards;
  }
  .footerCanvas4 {
    width: 100%;
    height: 100px;
    position: absolute;
    top: 380px;
    overflow: visible;
    z-index: 2;
    margin-bottom: 30px;
    animation: onOpacityAnimation 1s 0s forwards,
      canvasAnimation4 1s 0s forwards;
  }
  .footerCanvas5 {
    width: 100%;
    height: 100px;
    position: absolute;
    top: 440px;
    overflow: visible;
    z-index: 2;
    margin-bottom: 30px;
    animation: onOpacityAnimation 1s 0s forwards,
      canvasAnimation5 1s 0s forwards;
  }
  .footerCanvas6 {
    width: 100%;
    height: 100px;
    position: absolute;
    top: 500px;
    overflow: visible;
    z-index: 2;
    margin-bottom: 30px;
    animation: onOpacityAnimation 1s 0s forwards,
      canvasAnimation6 1s 0s forwards;
  }
  .footerCanvas7 {
    width: 100%;
    height: 100px;
    position: absolute;
    top: 550px;
    overflow: visible;
    z-index: 2;
    margin-bottom: 30px;
    animation: onOpacityAnimation 1s 0s forwards,
      canvasAnimation7 1s 0s forwards;
  }
  .footerCanvas8 {
    width: 100%;
    height: 100px;
    position: absolute;
    top: 500px;
    overflow: visible;
    z-index: 2;
    margin-bottom: 30px;
    animation: canvasAnimation8 1s 0s forwards;
  }
  .footerCanvas9 {
    width: 100%;
    height: 100px;
    position: absolute;
    top: 550px;
    overflow: visible;
    z-index: 2;
    margin-bottom: 30px;
    animation: canvasAnimation9 1s 0s forwards;
  }
  .footerCanvas10 {
    width: 100%;
    height: 100px;
    position: absolute;
    top: 600px;
    overflow: visible;
    z-index: 2;
    margin-bottom: 30px;
    animation: canvasAnimation10 1s 0s forwards;
  }
  .footerCanvas11 {
    width: 100%;
    height: 100px;
    position: absolute;
    top: 650px;
    overflow: visible;
    z-index: 2;
    margin-bottom: 30px;
    animation: canvasAnimation11 1s 0s forwards;
  }
  .footerCanvas12 {
    width: 100%;
    height: 100px;
    position: absolute;
    top: 700px;
    overflow: visible;
    z-index: 2;
    margin-bottom: 30px;
    animation: canvasAnimation12 1s 0s forwards;
  }

  @keyframes onOpacityAnimation {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .friendsPageWrapper {
    width: 1366px;
    height: 540px;
    position: relative;
    margin-right: auto;
    margin-left: auto;
    animation: onOpacityAnimation 0.5s 0s forwards;
  }

  .friendsPageWrapperHide {
    width: 1366px;
    height: 540px;
    position: relative;
    margin-right: auto;
    margin-left: auto;
    animation: offOpacityAnimation 0.5s 0s forwards;
  }

  .friendsPageTitle {
    text-align: center;
    font-family: "Urbanist";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #ffffff;
    letter-spacing: -2%;
    text-transform: uppercase;
    position: absolute;
    left: 648px;
    top: 65px;
    z-index: 4;
  }

  .friendsPageSubtitle {
    text-align: center;
    font-family: "Urbanist";
    font-style: normal;
    font-weight: 800;
    font-size: 60px;
    line-height: 72px;
    color: #ffffff;
    letter-spacing: -2%;
    text-transform: uppercase;
    position: absolute;
    left: 355px;
    top: 48px;
    z-index: 4;
  }

  .alexIMG {
    width: auto;
    height: auto;
    position: absolute;
    z-index: 3;
    top: 18px;
    left: 153px;
  }
  .denysIMG {
    width: auto;
    height: auto;
    position: absolute;
    z-index: 3;
    top: 186px;
    left: 546px;
  }
  .dianaIMG {
    width: auto;
    height: auto;
    position: absolute;
    left: 65%;
    z-index: 3;
    top: 105px;
    left: 939px;
  }
  .blot1 {
    // background-image: url(../../../assets/blot1.png);
    // background-size: contain;
    position: absolute;
    z-index: 3;
    width: 55px;
    height: 68px;
    left: 108px;
    top: 60px;
  }
  .blot2 {
    // background-image: url(../../../assets/blot2.png);
    // background-size: contain;
    // background-position: center;
    // background-repeat: no-repeat;
    position: absolute;
    z-index: 3;
    width: 113px;
    height: 116px;
    left: 395px;
    top: 150px;
  }
  .blot3 {
    // background-image: url(../../../assets/blot3.png);
    // background-size: cover;
    position: absolute;
    z-index: 3;
    width: 102px;
    height: 89px;
    left: 565px;
    top: -58px;
  }
  .blot4 {
    // background-image: url(../../../assets/blot4.png);
    // background-size: cover;
    position: absolute;
    z-index: 3;
    width: 113px;
    height: 96px;
    left: 748px;
    top: 130px;
  }
  .blot5 {
    // background-image: url(../../../assets/blot5.png);
    // background-size: cover;
    position: absolute;
    z-index: 3;
    width: 67px;
    height: 68px;
    left: 1026px;
    top: -35px;
  }
  .blot6 {
    // background-image: url(../../../assets/blot6.png);
    // background-size: cover;
    position: absolute;
    z-index: 3;
    width: 56px;
    height: 57px;
    left: 1150px;
    top: 180px;
  }
}

// .pp {
//   position: absolute;
//   top: -200px;
// }
