@import "../../App.module.scss";

.header {
  position: fixed;
  z-index: 20;
  padding: 22px 21px;
  top: 0;
  left: 0;
}
.menu {
  display: flex;
  align-items: center;
  display: none;
}
.headerWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  width: 44px;
  height: 22px;
  cursor: pointer;
}

.menu {
  display: none;
  align-items: center;
}

.navMenu {
  display: flex;
  align-items: center;
}

.navMenuItem {
  padding-top: 14px;
  padding-bottom: 14px;
  margin-right: 64px;
  &:first-child {
    margin-left: 24px;
  }

  &:last-child {
    margin-right: 48px;
  }
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  font-style: normal;
  color: $greyDark;
}

.navMenuItemText {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  font-style: normal;
  color: $greyDark;
  text-decoration: none;
}

.signUpButton {
  padding: 14px 31px;
  background-color: $black;
  border-radius: 10000px;
  color: $white;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  font-style: normal;
  cursor: pointer;
  border: none;
}

.resoursesMenuWrapper {
  display: flex;
  align-items: center;
}

.resoursesMenuIcon {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 8px solid $greyDark;
  margin-left: 8px;
}

@media (min-width: 1800px) {
  .logo {
    width: 56px;
    height: 28px;
  }
}

@media (max-width: 1366px) {
  .menu {
    display: none;
    align-items: center;
    display: auto;
  }
}
