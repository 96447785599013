@import "../../../App.module.scss";
.contactUs {
  padding: 80px 20px 20px 20px;
  position: relative;
  background-color: #ece3ce;
}
.mobailContactUsHeaderBackground {
  width: 100%;
  height: 60px;
  position: fixed;
  z-index: 10;
  background-color: #ece3ce;
  box-shadow: 0 4px 5px 0px rgba(0, 0, 0, 0.25);
  top: 0;
  left: 0;
}

.crossIcon {
  position: fixed;
  z-index: 11;
  top: 24px;
  right: 25px;
  cursor: pointer;
}
.title {
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: $grey;
  margin-bottom: 8px;
}

.subTitle {
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 800;
  font-size: 40px;
  line-height: 48px;
  letter-spacing: -0.02em;
  color: $black;
  margin-bottom: 48px;
}

.inputTitle {
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: $black;
  &:first-child {
    margin-bottom: 10px;
  }
  margin-bottom: 8px;
}

.input {
  padding-bottom: 5px;
  width: 100%;
  border: none;
  border-bottom: 1px solid $greyLight;
  background-color: #ece3ce;
  margin-bottom: 40px;
  &:focus {
    outline: none;
    border-bottom: 1px solid rgba(125, 119, 183, 1);
  }
  & ::placeholder {
    font-family: "Urbanist";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.02em;
    color: $greyLight;
  }
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #36324b;
}

.buttonTitle {
  margin-bottom: 16px;
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: $black;
}

.discussButtonCase {
  &:first-child {
    margin-bottom: 12px;
  }
  &:last-child {
    margin-bottom: 40px;
  }
  display: flex;
  flex-wrap: wrap;
}

.discussButton {
  flex: 1 1 100px;
  flex-shrink: 0;
  background: #cbc9e2;
  border-radius: 100px;
  border: none;
  padding: 8px 25px;
  margin-right: 8px;
  &:last-child {
    margin-right: 0px;
  }
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: $black;
  text-align: center;
  &:hover {
    color: rgba(6, 4, 3, 1);
    background-color: rgba(151, 146, 197, 1);
  }
}

.discussButtonActive {
  flex: 1 1 100px;
  flex-shrink: 0;
  background: rgba(101, 96, 147, 1);
  border-radius: 100px;
  border: none;
  padding: 8px 25px;
  margin-right: 8px;
  &:last-child {
    margin-right: 0px;
  }
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: $white;
  text-align: center;
}

.interestedButtonCase {
  margin-bottom: 12px;
  &:last-child {
    margin-bottom: 40px;
  }
  display: flex;
  flex-wrap: wrap;
}

.interestedButton {
  flex: 1 1 0;
  background: #cbc9e2;
  border-radius: 100px;
  border: none;
  padding: 8px 25px;
  margin-right: 8px;
  &:last-child {
    margin-right: 0px;
  }
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: $black;
  text-align: center;
  &:hover {
    color: rgba(6, 4, 3, 1);
    background-color: rgba(151, 146, 197, 1);
  }
}

.interestedButtonActive {
  flex: 1 1 0;
  background: #656093;
  border-radius: 100px;
  border: none;
  padding: 8px 25px;
  margin-right: 8px;
  &:last-child {
    margin-right: 0px;
  }
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: $white;
  text-align: center;
}

.budgetButtonCase {
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 40px;
  }
  display: flex;
  flex-wrap: wrap;
}

.budgetButton {
  flex: 1 1 0;
  background: #cbc9e2;
  border-radius: 100px;
  border: none;
  padding: 8px 25px;
  margin-right: 8px;
  &:last-child {
    margin-right: 0px;
  }
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: $black;
  text-align: center;
  &:hover {
    color: rgba(6, 4, 3, 1);
    background-color: rgba(151, 146, 197, 1);
  }
}

.budgetButtonActive {
  flex: 1 1 0;
  background: #656093;
  border-radius: 100px;
  border: none;
  padding: 8px 25px;
  margin-right: 8px;
  &:last-child {
    margin-right: 0px;
  }
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: $white;
  text-align: center;
}

.sendButton {
  padding: 14px 0px;
  width: 100%;
  border: none;
  background-color: $black;
  border-radius: 100px;
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #ffffff;
  cursor: pointer;
}

@media (min-width: 1366px) {
  .contactUs {
    padding: 100px 258px 100px 258px;
  }
  .crossIcon {
    position: fixed;
    top: 24px;
    right: 25px;
    cursor: pointer;
  }
  .title {
    font-family: "Urbanist";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: $grey;
    margin-bottom: 8px;
  }

  .subTitle {
    max-width: 638px;
    font-family: "Urbanist";
    font-style: normal;
    font-weight: 800;
    font-size: 60px;
    line-height: 72px;
    letter-spacing: -0.02em;
    color: $black;
    margin-bottom: 90px;
  }
  .nameCase {
    display: flex;
    margin-bottom: 50px;
  }

  .inputTitle {
    font-family: "Urbanist";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: $black;
    margin-bottom: 0px;
    &:first-child {
      margin-bottom: 0px;
    }
  }

  .nameCase .inputTitle {
    margin-left: 20px;
    margin-right: 20px;
    &:first-child {
      margin-left: 0px;
    }
  }

  .input {
    flex: 1 1 0;
    padding-bottom: 5px;
    border: none;
    border-bottom: 1px solid $greyLight;
    background-color: #ece3ce;
    &:focus {
      outline: none;
    }
    &::placeholder {
      font-family: "Urbanist";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.02em;
      color: $greyLight;
    }
    font-family: "Urbanist";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: #36324b;
  }

  .nameCase .input {
    margin-bottom: 0px;
  }

  .buttonTitle {
    margin-bottom: 16px;
    font-family: "Urbanist";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: $black;
  }

  .discussButtonBlock {
    display: flex;
    margin-bottom: 40px;
  }

  .discussButtonCase {
    &:first-child {
      margin-bottom: 0px;
    }
    &:last-child {
      margin-bottom: 0px;
    }
    display: flex;
    flex-wrap: nowrap;
  }

  .discussButton {
    flex: auto;
    background: #cbc9e2;
    border-radius: 100px;
    border: none;
    padding: 8px 25px;
    margin-right: 8px;
    font-family: "Urbanist";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.02em;
    color: $black;
    text-align: center;
    &:last-child {
      margin-right: 8px;
    }
  }

  .discussButtonActive {
    flex: auto;
    background: rgba(101, 96, 147, 1);
    border-radius: 100px;
    border: none;
    padding: 8px 25px;
    margin-right: 8px;
    font-family: "Urbanist";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.02em;
    color: $white;
    text-align: center;
    &:last-child {
      margin-right: 8px;
    }
  }

  .interestedButtonBlock {
    display: flex;
    margin-bottom: 40px;
  }

  .interestedButtonCase {
    margin-bottom: 0px;
    &:last-child {
      margin-bottom: 0px;
    }
    display: flex;
    flex-wrap: wrap;
  }

  .interestedButton {
    flex: auto;
    background: #cbc9e2;
    border-radius: 100px;
    border: none;
    padding: 8px 25px;
    margin-right: 8px;
    font-family: "Urbanist";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.02em;
    color: $black;
    text-align: center;
    &:last-child {
      margin-right: 8px;
    }
  }

  .interestedButtonActive {
    flex: auto;
    background: #656093;
    border-radius: 100px;
    border: none;
    padding: 8px 25px;
    margin-right: 8px;
    font-family: "Urbanist";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.02em;
    color: $white;
    text-align: center;
    &:last-child {
      margin-right: 8px;
    }
  }

  .budgetButtonBlock {
    display: flex;
    margin-bottom: 40px;
  }

  .budgetButtonCase {
    margin-bottom: 0px;
    &:last-child {
      margin-bottom: 0px;
    }
    display: flex;
    flex-wrap: wrap;
  }
  .contactBlock {
    display: flex;
    &:first-child {
      margin-right: 20px;
    }
  }
  .budgetButton {
    flex: auto;
    background: #cbc9e2;
    border-radius: 100px;
    border: none;
    padding: 8px 25px;
    margin-right: 8px;
    font-family: "Urbanist";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.02em;
    color: $black;
    text-align: center;
    &:last-child {
      margin-right: 8px;
    }
  }

  .budgetButtonActive {
    flex: auto;
    background: #656093;
    border-radius: 100px;
    border: none;
    padding: 8px 25px;
    margin-right: 8px;
    font-family: "Urbanist";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.02em;
    color: $white;
    text-align: center;
    &:last-child {
      margin-right: 8px;
    }
  }

  .contactBlock {
    display: flex;
    :first-child {
      margin-right: 20px;
    }
  }

  .sendButtonCase {
    display: flex;
    justify-content: end;
  }

  .sendButton {
    padding: 14px 0px;
    width: 164px;
    border: 2px solid rgba(6, 4, 3, 1);
    background-color: $black;
    border-radius: 100px;
    font-family: "Urbanist";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.02em;
    color: #ffffff;
    &:hover {
      background-color: rgba(255, 255, 255, 1);
      border: 2px solid rgba(6, 4, 3, 1);
      color: rgba(6, 4, 3, 1);
    }
    &:disabled {
      background-color: rgba(6, 4, 3, 1);
      border: 2px solid rgba(6, 4, 3, 1);
      color: rgba(255, 255, 255, 1);
      opacity: 50%;
    }
  }
}

@media (min-width: 1490px) {
  .subTitle {
    max-width: none;
    font-family: "Urbanist";
    font-style: normal;
    font-weight: 800;
    font-size: 60px;
    line-height: 72px;
    letter-spacing: -0.02em;
    color: $black;
    margin-bottom: 90px;
  }
}

textarea {
  resize: none;
  width: 100%;
}
